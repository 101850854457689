import React from "react";
import { Col } from "react-bootstrap";
import {
  GeoAlt,
  Calendar4Week,
  ArrowRightCircleFill,
} from "react-bootstrap-icons";
import { Loader } from "components/Loader";
import { connect } from "react-redux";
import moment from "moment";
import * as AppActions from "actions";
import { getFormattedPeriod } from "utils/index";
import { PATH_URLS, SIDEBAR_ITEM_KEYS } from "constants/index";
import TournamentList from "components/TournamentList";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showPastTournaments: false,
    };
  }
  async componentDidMount() {
    this.props.getMainEvent();
    this.props.getTournaments({
      includeSmallImage: true,
      year: 2024,
    });
    this.setState({
      loading: true,
    });
  }

  pastTournamentsHandler = () => {
    this.setState((prevState) => {
      return { showPastTournaments: !prevState.showPastTournaments };
    });
  };

  render() {
    const { upcomingTournament, tournamentsList, loadingMainEvent, loading } =
      this.props;

    if (loadingMainEvent || loading) {
      return (
        <Col className={"home-page-container"}>
          <div className={"align-center"}>
            <Loader />
          </div>
        </Col>
      );
    }
    return (
      <Col className={"home-page-container"}>
        {upcomingTournament && upcomingTournament.length > 0 && (
          <div className={"home-upcoming-event-container"}>
            <div className={"upcoming-event-details-container"}>
              <div className={"upcoming-label"}>
                {moment(upcomingTournament[0].startDate).isAfter(moment())
                  ? "Upcoming Event"
                  : "Ongoing Event"}
              </div>
              <div>
                <div className={"upcoming-event-title"}>
                  {upcomingTournament[0].name}
                </div>
                <div className={"display-flex align-center"}>
                  <GeoAlt size={14} />
                  &nbsp;&nbsp;
                  {upcomingTournament[0].cityName}&nbsp;&nbsp;|&nbsp;&nbsp;
                  <Calendar4Week size={14} />
                  &nbsp;&nbsp;
                  {getFormattedPeriod(
                    upcomingTournament[0].startDate,
                    upcomingTournament[0].endDate
                  )}
                </div>
              </div>
              <a
                href={`${PATH_URLS.EVENT}/${upcomingTournament[0].id}${SIDEBAR_ITEM_KEYS.DETAILS}`}
              >
                <div className={"upcoming-redirect"}>
                  View Event Details&nbsp;&nbsp;
                  <ArrowRightCircleFill size={14} />
                </div>
              </a>
            </div>
            <div
              className={"upcoming-event-image-container"}
              style={{
                backgroundImage: `url(data:image/jpeg;base64,${upcomingTournament[0].image})`,
              }}
            >
              &nbsp;
            </div>
          </div>
        )}
        <TournamentList year={2024} tournament={tournamentsList} />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  tournamentsList: state.appReducer.tournamentsList,
  loading: state.appReducer.loading,
  loadingMainEvent: state.appReducer.loadingMainEvent,
  upcomingTournament: state.appReducer.upcomingTournament,
});

const mapDispatchToProps = (dispatch) => ({
  getMainEvent: () => dispatch(AppActions.getUpcomingTournament()),
  getTournaments: (params) => dispatch(AppActions.getTournaments(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(HomePage);
export default withConnect;
