import React, {Fragment} from 'react';
import 'style/index.css';
import {Nav, NavDropdown, Row, Col} from 'react-bootstrap';
import {House} from 'react-bootstrap-icons';
import {PATH_URLS, SIDEBAR_ITEM_KEYS} from "constants/index";
import {checkRole, getFormattedPeriod, getSplitTitle, getUserName} from "utils";
import * as AppActions from 'actions';
import {connect} from 'react-redux';
import moment from 'moment';
import {ROLE_TYPES} from 'constants/index';

class NavbarMenu extends React.Component {
    render() {
        const {events = [], isLoggedIn, logout} = this.props;
        let userName = getUserName();
        return (
            <Col>
                <Row className={'navbar-root-row'}>
                    <Nav.Link href={PATH_URLS.HOME} className={'navbar-logo_over767'}>
                        <img src={window.location.origin + '/round-logo-notext-bvr.png'} className={'navbar-logo-img'} alt={'BVR logo'}/>
                        Beach Volley Romania
                    </Nav.Link>
                    
                    <Nav className={'navbar-buttons-over767'}>
                        <Nav.Item>
                            <Nav.Link eventKey="1" href={PATH_URLS.TOURNAMENTS}>
                                Tournaments
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2" href={PATH_URLS.RANKING}>
                                Ranking
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3" href={PATH_URLS.CONTACT}>
                                Contact
                            </Nav.Link>
                        </Nav.Item>
                        {isLoggedIn
                         ? <Nav.Item>
                             <NavDropdown title={`Hello, ${userName}`} alignRight>
                                 <NavDropdown.Item eventKey={4} href={PATH_URLS.USER_PROFILE}>
                                     My Profile
                                 </NavDropdown.Item>
                                 {(checkRole(ROLE_TYPES.SUPER_ADMIN) || checkRole(ROLE_TYPES.ADMIN) || checkRole(ROLE_TYPES.MANAGER)) &&
                                  <NavDropdown.Item eventKey={5} href={PATH_URLS.ADMIN_PROFILE}>
                                      Admin Dashboard
                                  </NavDropdown.Item>
                                 }
                                 <NavDropdown.Item eventKey={6} onClick={logout}>
                                     Logout
                                 </NavDropdown.Item>
                             </NavDropdown>
                         </Nav.Item>
                         : <Nav.Item>
                             <Nav.Link eventKey="4" href={PATH_URLS.LOGIN}>
                                 Login
                             </Nav.Link>
                         </Nav.Item>
                        }
                    </Nav>
                    
                    <Nav className={'navbar-buttons-under767_4col'}>
                        <Nav.Item>
                            <Nav.Link href={PATH_URLS.HOME} className={'navbar-logo'}>
                                <House size={15}/>
                            </Nav.Link>
                        </Nav.Item>
                        <NavDropdown title={'Tournaments'} alignRight>
                            {events && events.length > 0 && events.sort((a,b)=> moment(a.startDate).diff(moment(b.startDate))).map((ev, index) => {
                                let lastItem = events.length === index + 1;
                                return (
                                    <Fragment key={index}>
                                        <NavDropdown.Item eventKey={ev.id} href={`${PATH_URLS.EVENT}/${ev.id}${SIDEBAR_ITEM_KEYS.DETAILS}`}>
                                            <div className={'nav-dropdown-title'}>{getSplitTitle(ev.name).title}</div>
                                            <div className={'nav-dropdown-subtitle'}>{getSplitTitle(ev.name).subtitle}</div>
                                            <div
                                                className={'nav-dropdown-details'}>{getFormattedPeriod(ev.startDate, ev.endDate)} | {ev.cityName}</div>
                                        </NavDropdown.Item>
                                        {!lastItem && <NavDropdown.Divider/>}
                                    </Fragment>
                                );
                            })}
                        </NavDropdown>
                        <Nav.Item>
                            <Nav.Link eventKey="2" href={PATH_URLS.RANKING}>
                                Ranking
                                {/*<ListOl size={25}/>*/}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3" href={PATH_URLS.CONTACT}>
                                Contact
                                {/*<Telephone size={25}/>*/}
                            </Nav.Link>
                        </Nav.Item>
                        {isLoggedIn
                         ? <Nav.Item>
                             <NavDropdown title={`Hello!`} alignRight>
                                 <NavDropdown.Item eventKey={4} href={PATH_URLS.USER_PROFILE}>
                                     My Profile
                                 </NavDropdown.Item>
                                 {(checkRole(ROLE_TYPES.SUPER_ADMIN) || checkRole(ROLE_TYPES.ADMIN) || checkRole(ROLE_TYPES.MANAGER)) &&
                                  <NavDropdown.Item eventKey={5} href={PATH_URLS.ADMIN_PROFILE}>
                                      Admin Dashboard
                                  </NavDropdown.Item>
                                 }
                                 <NavDropdown.Item eventKey={6} onClick={logout}>
                                     Logout
                                 </NavDropdown.Item>
                             </NavDropdown>
                         </Nav.Item>
                         : <Nav.Item>
                             <Nav.Link eventKey="4" href={PATH_URLS.LOGIN}>
                                 Login
                             </Nav.Link>
                         </Nav.Item>
                        }
                    </Nav>
                
                </Row>
            </Col>
        );
    };
    
    componentDidMount() {
        this.props.getTournaments({year: moment().year()});
    }
    
    componentWillUnmount() {
        this.props.resetTournaments();
    }
}

const mapStateToProps = (state) => ({
    events: state.appReducer.tournamentsNavbarList
});

const mapDispatchToProps = (dispatch) => ({
    getTournaments: (params) => dispatch(AppActions.getTournamentsNavBar(params)),
    resetTournaments: () => dispatch(AppActions.resetTournaments())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(NavbarMenu);
export default withConnect;