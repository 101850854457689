import React from 'react';
import {Container, Row} from 'react-bootstrap';
import NavbarMenu from 'components/Navbar/Navbar';
import {RouterComponent} from 'components/Router';
import {Footer} from 'components/Footer';
import 'style/index.css';

const Layout = ({ loading, isLoggedIn, logout}) => {

    return (
        <Container fluid className={'root-container-layout'}>
            <Row className={'nav-bar-container'}>
                <NavbarMenu isLoggedIn={isLoggedIn} logout={logout}/>
            </Row>

            <Row className={'content-container'}>
                <RouterComponent isLoggedIn={isLoggedIn}/>
            </Row>

            <Row className={'footer'}>
                <Footer isLoggedIn={isLoggedIn} logout={logout}/>
            </Row>
        </Container>
    )
};

export default Layout;