import React from 'react';
import {PageTitle} from "components/PageTitle";
import {connect} from "react-redux";
import * as AppActions from "actions";
import {Loader} from "components/Loader";

class RegisterPage extends React.Component {
    onRegisterPlayer = (values) => {
        
        let body = {
            ...values,
            confirmPassword: undefined
        };
        this.props.registerPlayer(body);
    };
    
    render() {
        const {
            loading,
        } = this.props;
        if(loading){
            return <Loader/>
        }
        else return <div>here</div>
    }
    
    componentWillUnmount() {
    }
    
    componentDidMount() {
        let token = this.props.location.search.split('=')[1];
        this.props.confirmRegister({token: token})
    }
}

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading
});

const mapDispatchToProps = (dispatch) => ({
    confirmRegister: (token) => dispatch(AppActions.confirmRegister(token))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
export default withConnect;