import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Tools, ConeStriped} from 'react-bootstrap-icons';

export const PageInConstruction = () => {
    return (
        <div className={'padding-x-large'}>
            <Col xs={12} className={'display-flex justify-center text-center font-xx-large'}>
                <ConeStriped size={60}/>
            </Col>
            <Col xs={12} className={'display-flex justify-center text-center font-xx-large'}>
                Page Under Construction
            </Col>
            <Col xs={12} className={'display-flex justify-center text-center font-larger'}>
                Thank you for your patience !
            </Col>
        </div>
    )
};