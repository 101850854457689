import React, {Component, Fragment} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import {PageTitle} from 'components/PageTitle';
import {connect} from "react-redux";
import * as AppActions from "actions";
import {TableComponent} from 'components/Table';
import {ModalComponent} from "components/Modal";
import {List} from 'react-bootstrap-icons';
import HistoryDetailsModal from './historyDetailsModal';
import RankFilterForm from './rankFilterForm';
import moment from 'moment/moment';

class RankingPage extends Component {
    state = {
        openHistoryModal: false,
        playerDetails: {}
    };
    
    openPlayerHistoryModal = (item) => {
        this.props.getPlayerHistory({playerId: item.playerId});
        this.setState({
            openHistoryModal: true,
            playerDetails: item
        });
    };
    
    closeModal = () => {
        this.setState({
            openHistoryModal: false,
            playerDetails: {}
        });
    };
    
    filterData = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        this.props.getRanking({
            categoryId: category
        });
    };
    
    render() {
        const {rankingList, playerHistory, historyLoading} = this.props;
        const {openHistoryModal, playerDetails} = this.state;
        const header = [
            {display: 'Player', property: 'fullName'},
            {display: 'City', property: 'cityName'},
            {
                display: 'Total Points',
                property: 'totalPoints',
                emphasizedColumn: true,
                map: ((item, index) => ({
                        display: <div key={index} className={'font-bold text-right'}>
                            {item.totalPoints}
                        </div>
                    })
                )
            }
        
        ];
        const otherButtons = [
            {
                action: (item) => this.openPlayerHistoryModal(item),
                label: 'View History',
                icon: <List/>
            }
        ];
        
        return (
            <Col className={'page-container'}>
                <Row>
                    <PageTitle title={"Players' Ranking"}/>
                    <div style={{display: 'flex', width: '100%', padding: '12px', justifyContent: 'center'}}>
                        <div className={'display-grid'}>
                            <div className={'filter-container'}>
                                <RankFilterForm
                                    onSubmit={this.filterData}
                                />
                            </div>
                            <TableComponent
                                data={rankingList}
                                header={header}
                                indexColumn
                                otherIndependentButtons={otherButtons}
                            />
                            
                            <ModalComponent
                                show={openHistoryModal}
                                onHide={this.closeModal}
                                title={<Fragment>
                                    <div>{`${playerDetails.fullName} - ${playerDetails.totalPoints} pts`}</div>
                                    <div className={'modal-subtitle'}> Results History</div>
                                </Fragment>}
                            >
                                <HistoryDetailsModal playerHistory={playerHistory} loading={historyLoading}/>
                            </ModalComponent>
                        </div>
                    </div>
                </Row>
            </Col>
        );
    }
    
    componentDidMount() {
        this.props.getRanking({categoryId: 1});
    }
    
    componentWillUnmount() {
        this.props.resetRanking();
    }
}

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading,
    rankingList: state.appReducer.rankingList,
    playerHistory: state.appReducer.playerHistory,
    historyLoading: state.appReducer.historyLoading
    
});

const mapDispatchToProps = (dispatch) => ({
    getRanking: (values) => dispatch(AppActions.getRanking(values)),
    resetRanking: () => dispatch(AppActions.resetRanking()),
    getPlayerHistory: (params) => dispatch(AppActions.getPlayerHistory(params))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(RankingPage);
export default withConnect;