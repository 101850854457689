export const gameScoreModel = (game) =>({
    gameId: game.id,
    teamOneId: game.teamOneId,
    teamTwoId: game.teamTwoId,
    setResults: [
        {
            set: 1,
            teamOneScore: 0,
            teamTwoScore: 0
        },
        {
            set: 2,
            teamOneScore: 0,
            teamTwoScore: 0
        },
        {
            set: 3,
            teamOneScore: 0,
            teamTwoScore: 0
        }
    ]
});