import React from 'react';
import {Formik, Field, Form,} from 'formik';
import {CustomRadioCheckComponent} from 'components/RenderInput';

const TournamentRankingsFilterForm = ({onFilterRankings}) => {
    return (
        <Formik
            initialValues={{category: 1}}
            onSubmit={onFilterRankings}
        >
            <Form>
                <div className={'display-flex '}>
                    <div className={'display-flex checkbox-container'}>
                        <Field
                            name="category" type={'radio'} value={1}
                            component={CustomRadioCheckComponent}
                            label={'Men'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={2}
                            component={CustomRadioCheckComponent}
                            label={'Women'} submitOnChange/>
                    </div>
                
                </div>
            </Form>
        </Formik>
    
    )
};

export default TournamentRankingsFilterForm;
