import React from'react';
import {Breadcrumb} from 'react-bootstrap';


export const BreadcrumbComponent = (
    {
        links
    }
) => {
    return(
        <Breadcrumb>
            {links.map((link, index)=>{
                let isActive = links.length === index+1;
                return(
                    <Breadcrumb.Item key={index} href={link.path} active={isActive}>{link.name}</Breadcrumb.Item>
                )
            })}
        </Breadcrumb>
    )
};
