import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { Loader } from "components/Loader";
import { connect } from "react-redux";
import { getTournaments } from "actions";

import moment from "moment";
import { PageTitle } from "components/PageTitle";
import TournamentList from "components/TournamentList";

const Tournaments = ({ getTournaments, tournamentsList }) => {
  useEffect(() => {
    getTournaments({
      includeSmallImage: true,
    });
  }, []);

  const getTournamentsByYear = (tournamentsList) => {
    let tournaments = {};
    for (let i = 0; i < tournamentsList.length; i++) {
      let tournamentYear = moment(tournamentsList[i].startDate).year();
      if (tournaments[tournamentYear]) {
        tournaments[tournamentYear].push(tournamentsList[i]);
      } else {
        tournaments[tournamentYear] = [tournamentsList[i]];
      }
    }
    return tournaments;
  };

  if (!tournamentsList.length) {
    return (
      <Col className={"home-page-container"}>
        <div className={"align-center"}>
          <Loader />
        </div>
      </Col>
    );
  }

  const tournamentsByYear = getTournamentsByYear(tournamentsList);
  const tournaments = Object.keys(tournamentsByYear)
    .reverse()
    .map((year) => (
      <TournamentList
        key={year}
        year={year}
        tournament={tournamentsByYear[year]}
      />
    ));

  return (
    <Col className={"home-page-container"}>
      <PageTitle title={"All tournaments"} />
      {tournaments}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  tournamentsList: state.appReducer.tournamentsList,
  loading: state.appReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getTournaments: (params) => dispatch(getTournaments(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournaments);
