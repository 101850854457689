import React, {Component} from 'react';
import {Col, Button, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form, withFormik} from 'formik';
import {CustomDatePickerComponent, CustomDropdownComponent, CustomInputComponent, CustomTextAreaComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";
import ImageUploader from "components/ImageUploader/index";
import {checkRole} from 'utils';
import moment from 'moment';

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.cityID) {
        errors.cityID = 'Required';
    }
    if (!values.information) {
        errors.information = 'Required';
    }
    if (!values.startDate) {
        errors.startDate = 'Required';
    }
    if (!values.endDate) {
        errors.endDate = 'Required';
    }
    if (!values.numberOfMenTeamsAllowed) {
        errors.numberOfMenTeamsAllowed = 'Required';
    }
    if (!values.numberOfWomenTeamsAllowed) {
        errors.numberOfWomenTeamsAllowed = 'Required';
    }
    if(values.startDate && values.endDate && moment(values.startDate).isAfter(moment(values.endDate))){
        errors.endDate = 'Invalid period! Select an end date that is same or after the start date.'
    }
    if (!values.managerId && checkRole('SuperAdmin')) {
        errors.managerId = 'Required';
    }
    if(values.startDateRegister && values.endDateRegister && moment(values.startDateRegister).isAfter(moment(values.endDateRegister))){
        errors.endDateRegister = 'Invalid period! Select an end date that is same or after the start date.'
    }
    return errors;
};

const emptyFormObject = {
    name: '',
    startDate: '',
    endDate: '',
    cityID: '',
    information: '',
    image: '',
    facebookEventLink: '',
    contactEmail: '',
    contactPhone: '',
    startDateRegister: '',
    endDateRegister: '',
    managerId: '',
    numberOfMenTeamsAllowed: '',
    numberOfWomenTeamsAllowed: '',
};

class TournamentsForm extends Component {
    editorRef = React.createRef();
    state={
        imageTooBig: false
    };

    onFilesUploaded = async (values) => {
        if (this.editorRef.current) {
            this.props.onSubmit({
                ...values,
                image: this.editorRef.current.getImage().toDataURL('image/png', 1.0).replace('data:image/png;base64,', '')
            });
        }
        else {
            this.props.onSubmit({...values, image: null});
        }
    };
    
    handleBigImages = (isTooBig) => {
        this.setState({
            imageTooBig: isTooBig
        })
    };

    render() {
        const {onSubmit, initialValues = emptyFormObject, citiesOptions, saveLoading, usersOptions} = this.props;
        return (
            <Formik
                initialValues={initialValues || emptyFormObject}
                onSubmit={this.onFilesUploaded}
                validate={validate}
            >
                <Form>
                    <Row>
                        <Col xs={6} className={'padding-small'}>
                            <Field name="name" component={CustomInputComponent} label={'Name'}/>
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field
                                name="cityID"
                                component={CustomDropdownComponent}
                                options={citiesOptions}
                                label={'City'}
                            />
                        </Col>
                        
                        <Col xs={6} className={'padding-small'}>
                            <Field
                                name="startDate"
                                component={CustomDatePickerComponent}
                                label={'Start Date'}
                            />
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field
                                name="endDate"
                                component={CustomDatePickerComponent}
                                label={'End Date'}
                            />
                        </Col>
                        
                        <Col xs={12} className={'padding-small'}>
                            <Field name="information" component={CustomTextAreaComponent} label={'Description'}
                                   rows={5}/>
                        </Col>
                        <Col xs={12} className={'padding-small'}>
                            <div className={'label'}>Tournament's Image (max size: 5 Mb)</div>
                            <ImageUploader
                                onFilesUploaded={this.onFilesUploaded}
                                image={initialValues && initialValues.image ? `data:image/jpeg;base64,${initialValues.image}` : null}
                                editorRef={this.editorRef}
                                width={500}
                                height={300}
                                allowZoomOut={true}
                                imageIsTooBig={this.handleBigImages}
                            />
                            {this.state.imageTooBig &&
                            <div className={'display-flex justify-center color-error font-small'}>
                                This image is too big! The maximum size accepted is 5 Mb.
                            </div>}
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field name="facebookEventLink" component={CustomInputComponent}
                                   label={'Facebook Event Link'}/>
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field name="contactEmail" component={CustomInputComponent} label={'E-mail Address'}
                                   type={'email'}/>
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field name="contactPhone" component={CustomInputComponent} label={'Phone Number'}/>
                        </Col>
                        {checkRole('SuperAdmin') &&
                         <Col xs={6} className={'padding-small'}>
                             <Field name="managerId"
                                    component={CustomDropdownComponent}
                                    options={usersOptions}
                                    label={'Event Manager'}/>
                         </Col>
                        }
                    </Row>
                    <Row>
                        <Col xs={6} className={'padding-small'}>
                            <Field
                                name="startDateRegister"
                                component={CustomDatePickerComponent}
                                label={'Register Start Date'}
                            />
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field
                                name="endDateRegister"
                                component={CustomDatePickerComponent}
                                label={'Register End Date'}
                            />
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field name="numberOfMenTeamsAllowed" component={CustomInputComponent}
                                   label={'Max Number of Men Teams'}
                                   min={2}
                                   type={'number'}/>
                        </Col>
                        <Col xs={6} className={'padding-small'}>
                            <Field name="numberOfWomenTeamsAllowed" component={CustomInputComponent}
                                   label={'Max Number of Women Teams'}
                                   min={2}
                                   type={'number'}
                            />
                        </Col>
                        <Col xs={12} className={'text-right padding-small'}>
                            {saveLoading
                                ? <SpinnerButton label={'Saving'}/>
                                : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>} disabled={this.state.imageTooBig}/>
                            }
                        </Col>
                    </Row>
                </Form>
            </Formik>

        )
    }
};

export default TournamentsForm;
