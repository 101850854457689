import React from 'react';
import {LoginForm} from "components/LoginForm";
import {PageTitle} from "components/PageTitle";
import {connect} from "react-redux";
import {Col, Container, Row} from 'react-bootstrap';
import * as AppActions from "actions";
import {Link} from 'react-router-dom';
import {PATH_URLS} from 'constants/index'

const LoginPage = (
    {
        loading,
        login
    }) => {
    return (
        <Col className={'page-container'}>
            <Row>
                <PageTitle title={"Login"}/>
                <Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}
                     className={'contact-container container-with-flag'}>
                    <Container>
                        <LoginForm userAuthentication={login} loading={loading}/>
                    </Container>
                </Col>
                <Col  xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}
                      className={'text-center padding-xx-large'}
                >
                    <Link to={PATH_URLS.FORGOT_PASSWORD} className={'color-primary'}>Forgot password?</Link>
                </Col>
                <Col  xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}
                      className={'text-center padding-xx-large'}
                >
                    Don't have an account? <Link to={PATH_URLS.REGISTER} className={'color-primary'}>Sign up now</Link>
                </Col>
            </Row>
        </Col>
    )
};

const mapStateToProps = (state) => ({
    loading: state.appReducer.loadingLogin,
});

const mapDispatchToProps = (dispatch) => ({
    login: (credentials) => dispatch(AppActions.login(credentials)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withConnect;