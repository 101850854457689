import React from 'react';
import moment from "moment/moment";
import {PATH_URLS} from "constants/index";
import {Col} from 'react-bootstrap';

export const Footer = ({isLoggedIn, logout}) => {
    return (
        <Col className={'footer-root-col'}>
            &#9400;{moment().year()} Beach Volleyball Romania &nbsp;|&nbsp;
            <span><a href={PATH_URLS.ABOUT}>About</a></span> &nbsp;|&nbsp;
            <span><a href={PATH_URLS.CONTACT}>Contact</a></span> &nbsp;|&nbsp;
            {isLoggedIn
                ? <span className={'cursor-pointer'} onClick={logout}>Logout</span>
                :<span><a href={PATH_URLS.LOGIN}>Login</a></span>
            }

        </Col>
    )
};