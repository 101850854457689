import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {ExclamationCircleFill} from 'react-bootstrap-icons';

export const ModalComponent = (
    {
        show,
        onHide,
        title,
        children,
        size='lg'
    }) => {
    return (
        <Modal
            show={show}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
            backdrop={'static'}
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
};

export const ConfirmModal = (
    {
        show,
        onHide,
        title,
        content,
        icon = <ExclamationCircleFill/>,
        handleConfirm,
        handleClose
    }) => {
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {icon && icon}&nbsp;
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {content}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
};