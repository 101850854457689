import {ACTIONS_TYPES} from "constants/index";

const initialState = () => ({
    loading: false,
    loadingLogin: false,
    saveLoading: false,
    tournamentsList: [],
    managerTournamentsList: [],
    token: null,
    isLoggedIn: false,
    citiesList: [],
    rankingList:[],
    playersList:[],
    teamsList:[],
    gamesList:[],
    messages: [],
    userProfile:{},
    schedulesList: [],
    loadingPlayers:false,
    sponsorsList:[],
    usersList:[],
    tournamentRankingsList:[],
    upcomingTournament: [],
    tournamentsNavbarList: [],
    downloadLoading: false,
    loadingMainEvent: false,
    managerTournamentsLoading: false,
    historyLoading: false,
    loadingTournamentDetails: false,
    tournamentDetails: {},
    documents:[]
});

let reducer = {};

//region AUTHENTICATION
reducer[ACTIONS_TYPES.LOGIN_INIT] = (state) => ({
    ...state,
    loadingLogin: true
});
reducer[ACTIONS_TYPES.LOGIN_SUCCESS] = (state, action) => ({
    ...state,
    loadingLogin: false,
    token: action.payload,
    isLoggedIn: true
});
reducer[ACTIONS_TYPES.LOGIN_FAILURE] = (state) => ({
    ...state,
    loadingLogin: false,
    token: null,
    isLoggedIn: false
});
reducer[ACTIONS_TYPES.RESET_PASSWORD_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.RESET_PASSWORD_SUCCESS] = (state) => ({
    ...state,
    loading: false,
    isLoggedIn: true
});
reducer[ACTIONS_TYPES.RESET_PASSWORD_FAILURE] = (state) => ({
    ...state,
    loading: false,
    isLoggedIn: false
});
reducer[ACTIONS_TYPES.FORGOT_PASSWORD_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.FORGOT_PASSWORD_SUCCESS] = (state) => ({
    ...state,
    loading: false,
});
reducer[ACTIONS_TYPES.FORGOT_PASSWORD_FAILURE] = (state) => ({
    ...state,
    loading: false,
});
reducer[ACTIONS_TYPES.REGISTER_PLAYER_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.REGISTER_PLAYER_SUCCESS] = (state) => ({
    ...state,
    loading: false,
});
reducer[ACTIONS_TYPES.REGISTER_PLAYER_FAILURE] = (state) => ({
    ...state,
    loading: false,
});
reducer[ACTIONS_TYPES.CONFIRM_REGISTER_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.CONFIRM_REGISTER_SUCCESS] = (state) => ({
    ...state,
    loading: false,
});
reducer[ACTIONS_TYPES.CONFIRM_REGISTER_FAILURE] = (state) => ({
    ...state,
    loading: false,
});

reducer[ACTIONS_TYPES.LOGOUT_SUCCESS] = (state) => ({
    ...state,
    loading: false,
    token: null,
    isLoggedIn: false
});

reducer[ACTIONS_TYPES.GET_USER_PROFILE_INIT] = (state,action) => ({
    ...state,
    loading: true,
});
reducer[ACTIONS_TYPES.GET_USER_PROFILE_SUCCESS] = (state,action) => ({
    ...state,
    userProfile: action.payload,
    loading: false,
});
reducer[ACTIONS_TYPES.GET_USER_PROFILE_FAILURE] = (state,action) => ({
    ...state,
    loading: false,
    userProfile:{}
});
//endregion AUTHENTICATION

reducer[ACTIONS_TYPES.SAVE_ITEM_INIT] = (state) => ({
    ...state,
    saveLoading: true
});
reducer[ACTIONS_TYPES.SAVE_ITEM_SUCCESS] = (state, action) => ({
    ...state,
    saveLoading: false,
});
reducer[ACTIONS_TYPES.SAVE_ITEM_FAILURE] = (state) => ({
    ...state,
    saveLoading: false,
});

//region TOURNAMENTS
reducer[ACTIONS_TYPES.GET_TOURNAMENTS_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_TOURNAMENTS_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    tournamentsList: action.payload
});
reducer[ACTIONS_TYPES.GET_TOURNAMENTS_FAILURE] = (state) => ({
    ...state,
    loading: false,
    tournamentsList: []
});
reducer[ACTIONS_TYPES.GET_TOURNAMENT_BY_ID_INIT] = (state) => ({
    ...state,
    loadingTournamentDetails: true
});
reducer[ACTIONS_TYPES.GET_TOURNAMENT_BY_ID_SUCCESS] = (state, action) => ({
    ...state,
    loadingTournamentDetails: false,
    tournamentDetails: action.payload
});
reducer[ACTIONS_TYPES.GET_TOURNAMENT_BY_ID_FAILURE] = (state) => ({
    ...state,
    loadingTournamentDetails: false,
    tournamentDetails: {}
});

reducer[ACTIONS_TYPES.GET_TOURNAMENTS_NAVBAR_INIT] = (state) => ({
    ...state,
});
reducer[ACTIONS_TYPES.GET_TOURNAMENTS_NAVBAR_SUCCESS] = (state, action) => ({
    ...state,
    tournamentsNavbarList: action.payload
});
reducer[ACTIONS_TYPES.GET_TOURNAMENTS_NAVBAR_FAILURE] = (state) => ({
    ...state,
    tournamentsNavbarList: []
});
reducer[ACTIONS_TYPES.GET_MANAGER_TOURNAMENTS_INIT] = (state) => ({
    ...state,
    managerTournamentsLoading: true
});
reducer[ACTIONS_TYPES.GET_MANAGER_TOURNAMENTS_SUCCESS] = (state, action) => ({
    ...state,
    managerTournamentsLoading: false,
    managerTournamentsList: action.payload
});
reducer[ACTIONS_TYPES.GET_MANAGER_TOURNAMENTS_FAILURE] = (state) => ({
    ...state,
    managerTournamentsLoading: false,
    managerTournamentsList: []
});
reducer[ACTIONS_TYPES.GET_UPCOMING_TOURNAMENT_INIT] = (state) => ({
    ...state,
    loadingMainEvent: true
});
reducer[ACTIONS_TYPES.GET_UPCOMING_TOURNAMENT_SUCCESS] = (state, action) => ({
    ...state,
    loadingMainEvent: false,
    upcomingTournament: action.payload
});
reducer[ACTIONS_TYPES.GET_UPCOMING_TOURNAMENT_FAILURE] = (state) => ({
    ...state,
    loadingMainEvent: false,
    upcomingTournament: []
});
//endregion TOURNAMENTS
//
// region CITIES
reducer[ACTIONS_TYPES.GET_CITIES_INIT] = (state) => ({
    ...state,
    // loading: true
});
reducer[ACTIONS_TYPES.GET_CITIES_SUCCESS] = (state, action) => ({
    ...state,
    // loading: false,
    citiesList: action.payload
});
reducer[ACTIONS_TYPES.GET_CITIES_FAILURE] = (state) => ({
    ...state,
    // loading: false,
    citiesList: []
});
//endregion CITIES

// region RANKING
reducer[ACTIONS_TYPES.GET_RANKING_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_RANKING_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    rankingList: action.payload
});
reducer[ACTIONS_TYPES.GET_RANKING_FAILURE] = (state) => ({
    ...state,
    loading: false,
    rankingList: []
});
//endregion RANKING

// region PLAYERS
reducer[ACTIONS_TYPES.GET_PLAYERS_INIT] = (state) => ({
    ...state,
    loadingPlayers: true
});
reducer[ACTIONS_TYPES.GET_PLAYERS_SUCCESS] = (state, action) => ({
    ...state,
    loadingPlayers: false,
    playersList: action.payload
});
reducer[ACTIONS_TYPES.GET_PLAYERS_FAILURE] = (state) => ({
    ...state,
    loadingPlayers: false,
    playersList: []
});
//endregion PLAYERS
//
// region PLAYERS
reducer[ACTIONS_TYPES.GET_SIZES_INIT] = (state) => ({
    ...state,
    // loading: true
});
reducer[ACTIONS_TYPES.GET_SIZES_SUCCESS] = (state, action) => ({
    ...state,
    // loading: false,
    sizesList: action.payload
});
reducer[ACTIONS_TYPES.GET_SIZES_FAILURE] = (state) => ({
    ...state,
    // loading: false,
    sizesList: []
});
//endregion PLAYERS
//
// region TEAMS
reducer[ACTIONS_TYPES.GET_TEAMS_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_TEAMS_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    teamsList: action.payload
});
reducer[ACTIONS_TYPES.GET_TEAMS_FAILURE] = (state) => ({
    ...state,
    loading: false,
    teamsList: []
});
//endregion TEAMS
//
// region GAMES
reducer[ACTIONS_TYPES.GET_GAMES_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_GAMES_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    gamesList: action.payload
});
reducer[ACTIONS_TYPES.GET_GAMES_FAILURE] = (state) => ({
    ...state,
    loading: false,
    gamesList: []
});
//endregion GAMES
//
// region PLAYER HISTORY
reducer[ACTIONS_TYPES.GET_PLAYER_HISTORY_INIT] = (state) => ({
    ...state,
    historyLoading: true
});
reducer[ACTIONS_TYPES.GET_PLAYER_HISTORY_SUCCESS] = (state, action) => ({
    ...state,
    historyLoading: false,
    playerHistory: action.payload
});
reducer[ACTIONS_TYPES.GET_PLAYER_HISTORY_FAILURE] = (state) => ({
    ...state,
    historyLoading: false,
    playerHistory: []
});
//endregion PLAYER HISTORY
//
// region SCHEDULES
reducer[ACTIONS_TYPES.GET_SCHEDULES_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_SCHEDULES_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    schedulesList: action.payload
});
reducer[ACTIONS_TYPES.GET_SCHEDULES_FAILURE] = (state) => ({
    ...state,
    loading: false,
    schedulesList: []
});
//endregion SCHEDULES
//
// region SPONSORS
reducer[ACTIONS_TYPES.GET_SPONSORS_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_SPONSORS_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    sponsorsList: action.payload
});
reducer[ACTIONS_TYPES.GET_SPONSORS_FAILURE] = (state) => ({
    ...state,
    loading: false,
    sponsorsList: []
});
//endregion SPONSORS
//
// region USERS
reducer[ACTIONS_TYPES.GET_USERS_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_USERS_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    usersList: action.payload
});
reducer[ACTIONS_TYPES.GET_USERS_FAILURE] = (state) => ({
    ...state,
    loading: false,
    usersList: []
});
//endregion USERS
//
// region TOURNAMENT RANKINGS
reducer[ACTIONS_TYPES.GET_TOURNAMENT_RANKINGS_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_TOURNAMENT_RANKINGS_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    tournamentRankingsList: action.payload
});
reducer[ACTIONS_TYPES.GET_TOURNAMENT_RANKINGS_FAILURE] = (state) => ({
    ...state,
    loading: false,
    tournamentRankingsList: []
});
//endregion TOURNAMENT RANKINGS

// region DOWNLOAD
reducer[ACTIONS_TYPES.DOWNLOAD_REPORT_INIT] = (state) => ({
    ...state,
    downloadLoading: true
});
reducer[ACTIONS_TYPES.DOWNLOAD_REPORT_SUCCESS] = (state, action) => ({
    ...state,
    downloadLoading: false,
});
reducer[ACTIONS_TYPES.DOWNLOAD_REPORT_FAILURE] = (state) => ({
    ...state,
    downloadLoading: false,
});
//endregion DOWNLOAD

// region GET FILES
reducer[ACTIONS_TYPES.GET_DOCUMENTS_INIT] = (state) => ({
    ...state,
    loading: true
});
reducer[ACTIONS_TYPES.GET_DOCUMENTS_SUCCESS] = (state, action) => ({
    ...state,
    loading: false,
    documents: action.payload
});
reducer[ACTIONS_TYPES.GET_DOCUMENTS_FAILURE] = (state) => ({
    ...state,
    loading: false,
});
//endregion GET FILES
// region UPLOAD FILES
reducer[ACTIONS_TYPES.UPLOAD_DOCUMENTS_INIT] = (state) => ({
    ...state,
    saveLoading: true
});
reducer[ACTIONS_TYPES.UPLOAD_DOCUMENTS_SUCCESS] = (state) => ({
    ...state,
    saveLoading: false,
});
reducer[ACTIONS_TYPES.UPLOAD_DOCUMENTS_FAILURE] = (state) => ({
    ...state,
    saveLoading: false,
});
//endregion UPLOAD FILES

//region NOTIFICATION REDUCER

const createNotification = (() => {
    let id = 0;
    return (notification) => {
        const currentId = id++;
        return {
            id: currentId,
            message: notification.message,
            timeout: undefined,
            iconType: notification.icon,
            iconColor: notification.color,
            className: notification.className,
        };
    };
})();

reducer[ACTIONS_TYPES.ADD_NOTIFICATION] = (state, action) => {
    const notification = action.payload;
    const message = createNotification(notification);
    const messages = state.messages.concat(message);
    return {
        ...state,
        messages,
    };
};
reducer[ACTIONS_TYPES.REMOVE_NOTIFICATION] = (state, action) => {
    const notificationId = action.payload;
    const messages = [].concat(state.messages).filter((message) => message.id !== notificationId);
    return {
        ...state,
        messages,
    };
};

//endregion NOTIFICATION REDUCER

const mainReducer = (state = initialState(), action) => {
    return reducer[action.type] ? reducer[action.type](state, action) : state;
};

export default mainReducer;