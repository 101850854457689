import React, {Component, Fragment} from 'react';
import {DOC_TYPES, PATH_URLS} from 'constants/index';
import {AddButton} from "components/Buttons";
import {BreadcrumbComponent} from 'components/Breadcrumb';
import FilesFilterForm from 'pages/admin_pages/agreementFiles/filterForm';
import * as AppActions from 'actions';
import {connect} from 'react-redux';
import {TableComponent} from 'components/Table';
import {ConfirmModal, ModalComponent} from 'components/Modal';
import AgreementFilesForm from 'pages/admin_pages/agreementFiles/form';
import {FileEarmarkArrowDown} from 'react-bootstrap-icons';
import {base64ToArrayBuffer} from 'actions';

class Admin_AgreementFiles extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        deleteItem: {},
        filter: {}
    };
    
    onOpenForm = () => {
        this.setState({
            openForm: true
        });
    };
    
    closeForm = () => {
        this.setState({
            openForm: false
        });
    };
    
    uploadFile = async (values, uploadedFile) => {
        const body = {
            ...values,
            file: uploadedFile.fileData,
            fileType: uploadedFile.fileType
        };
        await this.props.uploadTournamentDocument(body);
        this.props.getTournamentDocuments(this.state.filter);
        this.closeForm();
    };
    
    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        });
    };
    
    onConfirmDelete = async () => {
        await this.props.deleteFile(this.state.deleteItem);
        this.props.getTournamentDocuments(this.state.filter);
        this.closeConfirm();
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        });
    };
    onDownloadFile = (doc)=>{
        base64ToArrayBuffer({
            fileContents: doc.file,
            contentType: doc.fileType || 'application/pdf',
            fileDownloadName: doc.name
        })
    }
    
    filterData = (values) => {
        this.props.getTournamentDocuments({tournamentId: values.tournamentId});
        this.setState((prevState) => ({
            filter: {
                ...prevState.filter,
                tournamentId: values.tournamentId
            }
        }));
    }
    
    render() {
        const {tournamentsList, documents, loading, saveLoading} = this.props;
        const {filter, openForm, openConfirm, deleteItem} = this.state;
        
        const otherButtons = [
            {
                action: (item) => {
                    this.onDownloadFile(item);
                },
                label: 'Download File',
                icon: <FileEarmarkArrowDown/>
            }
        ];
        
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_AGREEMENT_FILES, name: 'Agreement Files'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Agreement Files</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                    {tournamentsList && tournamentsList.length > 0 &&
                     <Fragment>
                         <div className={'filter-container'}>
                             <FilesFilterForm
                                 onSubmit={this.filterData}
                                 initialValues={{
                                     ...filter,
                                     tournamentId: filter.tournamentId || tournamentsList[0].id
                                 }}
                                 tournamentsList={tournamentsList}
                             />
                         </div>
                        
                         <TableComponent
                             data={documents}
                             header={header}
                             removable={true}
                             onDeleteItem={this.onDeleteItem}
                             loading={loading}
                             otherButtons={otherButtons}
                         />
                        
                         <ModalComponent
                             show={openForm}
                             onHide={this.closeForm}
                             title={'Upload File'}
                         >
                             <AgreementFilesForm
                                 initialValues={{tournamentId: filter.tournamentId}}
                                 onSubmit={this.uploadFile}
                                 saveLoading={saveLoading}
                             />
                         </ModalComponent>
    
                         <ConfirmModal
                             show={openConfirm}
                             onHide={this.closeConfirm}
                             title={`Remove File`}
                             handleConfirm={this.onConfirmDelete}
                             handleClose={this.closeConfirm}
                             content={`Are you sure you want to remove ${deleteItem.name} file?`}
                         />
                     </Fragment>
                    }
                </div>
            </div>
        );
    }
    
    componentWillUnmount() {
        this.props.resetTournaments();
    }
    
    async componentDidMount() {
        const tournaments = await this.props.getTournaments();
        if (tournaments && tournaments.length > 0) {
            this.props.getTournamentDocuments({tournamentId: tournaments[0].id});
            await this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    tournamentId: tournaments[0].id
                }
            }));
        }
    }
}

const mapStateToProps = (state) => ({
    tournamentsList: state.appReducer.managerTournamentsList,
    loading: state.appReducer.loading,
    saveLoading: state.appReducer.saveLoading,
    documents: state.appReducer.documents
});

const mapDispatchToProps = (dispatch) => ({
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
    getTournamentDocuments: (params) => dispatch(AppActions.getTournamentDocuments(params)),
    uploadTournamentDocument: (body) => dispatch(AppActions.uploadTournamentDocument(body)),
    deleteFile: (file) => dispatch(AppActions.deleteFile(file))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_AgreementFiles);
export default withConnect;

const header = [
    {
        display: 'File Name',
        property: 'name'
    }
];
