import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import reducers from "reducers";
import { routerMiddleware, connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState) => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );

  return store;
};

const store = configureStore();

export default store;
