import React from 'react';
import RegisterForm from "pages/register/form";
import {PageTitle} from "components/PageTitle";
import {connect} from "react-redux";
import {Col, Container, Row} from 'react-bootstrap';
import * as AppActions from "actions";
import {Link} from 'react-router-dom';
import {PATH_URLS} from 'constants/index';

class RegisterPage extends React.Component {
    onRegisterPlayer = (values) => {
        
        let body = {
            ...values,
            confirmPassword: undefined
        };
        this.props.registerPlayer(body);
    };
    
    render() {
        const {
            loading,
            citiesOptions
        } = this.props;
        return (
            <Col className={'page-container'}>
                <Row>
                    <PageTitle title={"Sign up"}/>
                    <Col xs={12} md={6} className={'description-container'}>
                        <p className={'description-logo-img-container'}>
                            <img src={process.env.PUBLIC_URL + 'BVR - logo(png).png'} className={'description-logo-img'} alt={'BVR logo'}/>
                        </p>
                        <p className={'color-primary font-larger'}>
                            Welcome to&nbsp;<b>Beach Volley Romania</b>!
                        </p>
                        <p>
                            This is an online platform dedicated to the amateur beach volleyball players community. Here you can be up-to-date with all the beach volleyball events that matter to you, see the registered teams, games schedules, results and rankings!
                        </p>
                        <p>
                            <div className={'color-primary font-larger'}>&nbsp;<b>Sign up</b>&nbsp;and be part of the community!</div>
                            <div>Sign up now to be able to register to our volleyball events and to see your ranking!</div>
                        </p>
                        <p className={'register-back-to-login'}>
                            <div >If you already have an account,</div>
                            <div>go back to <Link to={PATH_URLS.LOGIN} className={'color-primary'}>Login</Link></div>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className={'container-with-flag'}>
                        <Container>
                            <RegisterForm
                                loading={loading}
                                citiesOptions={citiesOptions}
                                onSubmit={this.onRegisterPlayer}
                            />
                        </Container>
                    </Col>
                </Row>
            </Col>
        );
    }
    
    componentWillUnmount() {
        this.props.resetCities();
    }
    
    componentDidMount() {
        this.props.getCities();
    }
}

const mapStateToProps = (state) => ({
    citiesOptions: state.appReducer.citiesList,
    loading: state.appReducer.loading
});

const mapDispatchToProps = (dispatch) => ({
    getCities: () => dispatch(AppActions.getCities()),
    resetCities: () => dispatch(AppActions.resetCities()),
    registerPlayer: (values) => dispatch(AppActions.registerPlayer(values))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
export default withConnect;