import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Check2Square} from 'react-bootstrap-icons';
import {SubmitButton, BackButton, SpinnerButton} from 'components/Buttons';
import {EVENT_CATEGORY} from 'constants/index';


const PreviewRegister = (
    {
        data,
        playersList,
        femaleRegister,
        saveLoading,
        handleBackToRegister,
        handleConfirmRegister,
        citiesOptions,
        sizesOptions
    }) => {
    
    const getPlayerDetails = (playerNo)=>{
        let details={};

        if(data[`${playerNo}Id`]){
           let selectedPlayer = playersList.find(player => player.playerId === +data[`${playerNo}Id`]);
            let size = sizesOptions && sizesOptions.find((size) => size.id === +data[`${playerNo}SizeId`]);

            details={
                ...selectedPlayer,
                sizeName: size?.name,
            }
        }
        else{
            let city = citiesOptions && citiesOptions.find((city) => city.id === +data[`${playerNo}CityId`]);
            let size = sizesOptions && sizesOptions.find((size) => size.id === +data[`${playerNo}SizeId`]);

            details={
                ...data,
                cityName: city?.name,
                sizeName: size?.name,
                firstName: data[`${playerNo}FirstName`],
                lastName: data[`${playerNo}LastName`],
            }
        }

        return details;
    };

    return (
        <Fragment>
            <Row className={'form-container'}>

                <Col xs={12} className={femaleRegister ? 'preview-team-name-FEMALE' : 'preview-team-name-MALE'}>
                    {data.teamName}
                    <div className={'preview-category-name'}>{data.category === EVENT_CATEGORY.MALE ? 'MEN' : 'WOMEN'}</div>
                </Col>
                {[1, 2].map((playerNo, index) => {
                    let player;
                    if (playerNo === 1) {
                        player = 'playerOne';
                    }
                    else player = 'playerTwo';

                    let playerDetails = getPlayerDetails(player);
                    
                    return (
                        <Col xs={6} key={index} className={'preview-player-section'}>

                                    <div className={'preview-player-section-title'}>
                                        {`#${playerNo} ${playerDetails.lastName} ${playerDetails.firstName}`}
                                    </div>
                                    <Row key={index} className={'preview-player-data-row'}>
                                        <Col xs={6} className={'preview-player-label'}>
                                            City
                                        </Col>
                                        <Col xs={6} className={'preview-emphasized-data'}>
                                            {playerDetails.cityName ? playerDetails.cityName : '-'}
                                        </Col>
                                    </Row>
                                    <Row key={index} className={'preview-player-data-row'}>
                                        <Col xs={6} className={'preview-player-label'}>
                                            T-shirt Size
                                        </Col>
                                        <Col xs={6} className={'preview-emphasized-data'}>
                                            {playerDetails.sizeName ? playerDetails.sizeName : '-'}
                                        </Col>
                                    </Row>
                        </Col>
                    )
                })}
                <Col xs={12} className={'preview-agreement-section'}>
                    {data.rules &&
                    <div>
                        <Check2Square/>&nbsp;
                        We have read and we agree with the Rules of the tournament
                    </div>
                    }
                    {data.termsAndConditions &&
                    <div>
                        <Check2Square/>&nbsp;
                        We have read the Terms and Conditions of the tournament
                    </div>
                    }
                </Col>
                <Col xs={12} className={'preview-buttons-section'}>
                    <BackButton label={'Back to Form'} onClick={handleBackToRegister}/>
                    {saveLoading
                        ? <SpinnerButton label={'Saving'}/>
                        :
                        <SubmitButton label={'Finalize Registration'} type={'button'} onClick={handleConfirmRegister}/>
                    }

                </Col>
            </Row>
        </Fragment>
    )

};

export default PreviewRegister;
