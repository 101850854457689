import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CheckCircleFill, CircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form} from 'formik';
import {CustomInputComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";
import {EVENT_CATEGORY} from 'constants/index';
import moment from 'moment/moment';

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    
    // if (!values.startDate) {
    //     errors.startDate = 'Required';
    // }
    
    
    return errors;
};

const SETS_INDEXES = [1,2,3];
const GameScoreForm = ({onSubmit, initialValues, game, saveLoading}) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
        >
            <Form>
                <Row className={'team-score-row'}>
                    <Col xs={12} className={'flex-display align-center color-primary'}>
                        <CircleFill
                            size={13}
                            color={game.category === EVENT_CATEGORY.MALE ? '#002b7f' : '#fcd116'}
                        />&nbsp;
                        #{game.gameNumber}&nbsp;
                        {game.teamOneName}&nbsp;vs.&nbsp;
                        {game.teamTwoName}&nbsp;|&nbsp;
                        {moment(game.startDate).format('DD-MM-YYYY')}&nbsp;|&nbsp;{moment(game.startDate).format('HH:mm')}
                    </Col>
                </Row>
                <Row className={'team-score-row'}>
                    <Col xs={4} md={{span: 3, offset: 1}}></Col>
                    <Col xs={8} md={7} className={'score-set-fields-container'}>
                        <div>Set 1</div>
                        <div>Set 2</div>
                        <div>Set 3</div>
                    </Col>
                </Row>
                <Row className={'team-score-row'}>
                    <Col xs={4} md={{span: 3, offset: 1}}>{game.teamOneName}:</Col>
                    <Col xs={8} md={7}  className={'score-set-fields-container'}>
                        {SETS_INDEXES.map(set=>(
                            <Field key={set} name={`setResults[${set-1}.teamOneScore]`} component={CustomInputComponent}/>
                        ))}
                    </Col>
                </Row>
                <Row className={'team-score-row'}>
                    <Col xs={4} md={{span: 3, offset: 1}}>{game.teamTwoName}:</Col>
                    <Col xs={8} md={7}  className={'score-set-fields-container'}>
                        {SETS_INDEXES.map(set=>(
                            <Field key={set} name={`setResults[${set-1}.teamTwoScore]`} component={CustomInputComponent}/>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'text-right padding-small'}>
                        {saveLoading
                         ? <SpinnerButton label={'Saving'}/>
                         : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>
    
    )
};

export default GameScoreForm;
