import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {PageTitle} from 'components/PageTitle';
import {EventInfoBanner} from 'components/EventInfoBanner';
import {TableComponent} from 'components/Table';
import TournamentRankingsFilterForm from 'pages/eventDetails/ranking/filterForm';
import {connect} from "react-redux";
import * as AppActions from "actions";

class EventRanking extends React.Component {
    onFilterRankings = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        let params = {tournamentId: this.props.event?.id, categoryId: category};
        this.props.getTournamentRankings(params);
    };

    render() {
        const {tournamentRankingsList, loading, event} = this.props;

        const header = [
            {
                display: 'Place',
                property: 'place'
            },
            {
                display: 'Team Name',
                property: 'teamName',
                emphasizedColumn: true
            },
            {
                display: 'Player 1',
                property: 'player1',
                emphasizedColumn: true,
                map: (item, index) => ({display: <div key={index}>{item.players[0]}</div>})
            },
            {
                display: 'Player 2',
                property: 'player2',
                emphasizedColumn: true,
                map: (item, index) => ({display: <div key={index}>{item.players[1]}</div>})
            },

            {
                display: 'Points',
                property: 'points'
            }
        ];
        return (
            <Row>
                <Col xs={12}>
                    <EventInfoBanner event={event} />
                    <PageTitle title={'Results'} />
                </Col>
                <div className={'filter-form-container'}>
                    <TournamentRankingsFilterForm onFilterRankings={this.onFilterRankings} />
                </div>
                <Col xs={12}>
                    <TableComponent
                        data={tournamentRankingsList}
                        header={header}
                        loading={loading}
                        noDataMessage={'The results are not available yet'}
                    />
                </Col>

            </Row>
        );
    }

    componentDidMount() {
        const eventId = this.props.match.path.split("/")[2];
        let params = {tournamentId: eventId, categoryId: 1};
        this.props.getTournamentRankings(params);
    }

    componentWillUnmount() {
        this.props.resetTournamentRankings();
    }
}

const mapStateToProps = (state) => ({
    tournamentRankingsList: state.appReducer.tournamentRankingsList,
    loading: state.appReducer.loading,
    event: state.appReducer.tournamentDetails,
});

const mapDispatchToProps = (dispatch) => ({
    getTournamentRankings: (item) => dispatch(AppActions.getTournamentRankings(item)),
    resetTournamentRankings: () => dispatch(AppActions.resetTournamentRankings())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EventRanking);
export default withConnect;