import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {ArrowRightCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form,} from 'formik';
import {CustomInputComponent} from 'components/RenderInput';
import {SpinnerButton, SubmitButton} from "components/Buttons";

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.username) {
        errors.username = 'Required';
    }
    if (!values.password) {
        errors.password = 'Required';
    }

    return errors;
};

export const LoginForm = ({userAuthentication, loading}) => {
    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            onSubmit={userAuthentication}
            validate={validate}
        >
            <Form>
                <Row>
                    <Col xs={12} className={'padding-small'}>
                        <Field name="username" component={CustomInputComponent} label={'Username or Email'}/>
                    </Col>
                    <Col xs={12} className={'padding-small'}>
                        <Field name="password" type={'password'} component={CustomInputComponent}
                               label={'Password'}/>
                    </Col>
                    <Col xs={12} className={'text-center padding-small'}>
                        {loading
                            ? <SpinnerButton label={'Login'}/>
                            : <SubmitButton label={'Login '} icon={<ArrowRightCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>

    )
};