import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form, withFormik} from 'formik';
import {CustomDropdownComponent, CustomInputComponent, CustomRadioCheckComponent, CustomTextAreaComponent, CustomTimePickerComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.startDate) {
        errors.startDate = 'Required';
    }
    if (!values.scheduleId) {
        errors.scheduleId = 'Required';
    }
    if (!values.teamOneId) {
        errors.teamOneId = 'Required';
    }
    if (!values.teamTwoId) {
        errors.teamTwoId = 'Required';
    }
    if (!values.gameNumber) {
        errors.gameNumber = 'Required';
    }
    if(values.teamOneId && values.teamTwoId && values.teamOneId === values.teamTwoId){
        errors.teamTwoId = 'Please choose a different team';
    }

    return errors;
};

const emptyFormObject = {
    startDate: '',
    scheduleId: '',
    teamOneId: '',
    teamTwoId: '',
    gameNumber: '',
};

const GamesForm = ({onSubmit, initialValues, schedulesList, teamsList, saveLoading, handleSelectCategory}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
            validate={validate}
        >
            <Form>
                <Row>
                    <Col xs={12} sm={3} className={'padding-small'}>
                        <Field name="gameNumber" component={CustomInputComponent} label={'Game Number'}/>
                    </Col>
                    <Col xs={12} sm={6} className={'padding-small'}>
                        <Field
                            name="scheduleId"
                            component={CustomDropdownComponent}
                            options={schedulesList}
                            label={'Schedule (Day)'}
                        />
                    </Col>
                    <Col xs={12} sm={3} className={'padding-small'}>
                        <Field
                            name="startDate"
                            component={CustomTimePickerComponent}
                            label={'Start Time'}
                        />
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={12} sm={6} className={'padding-small'}>
                        <div className={'label'}>Category</div>
                        <div className={'display-flex checkbox-container_withLabel'}>
                            <Field
                                name="category" type={'radio'} value={1}
                                component={CustomRadioCheckComponent}
                                label={'Men'}
                                onChangeCallback={handleSelectCategory}
                            />
                            <Field
                                name="category" type={'radio'} value={2}
                                component={CustomRadioCheckComponent}
                                label={'Women'}
                                onChangeCallback={handleSelectCategory}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} className={'padding-small'}>
                        <Field
                            name="teamOneId"
                            component={CustomDropdownComponent}
                            options={teamsList}
                            label={'First Team'}
                        />
                    </Col>
                    <Col xs={12} sm={6} className={'padding-small'}>
                        <Field
                            name="teamTwoId"
                            component={CustomDropdownComponent}
                            options={teamsList}
                            label={'Second Team'}
                        />
                    </Col>
                    <Col xs={12} className={'text-right padding-small'}>
                        {saveLoading
                            ? <SpinnerButton label={'Saving'}/>
                            : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>

    )
};

export default GamesForm;
