import React from 'react';
import {Col, Row} from 'react-bootstrap'
import {PageTitle} from 'components/PageTitle'
import {getFormattedPeriod, getFormattedURL} from 'utils';
import {Facebook} from 'react-bootstrap-icons';
import {connect} from 'react-redux';

const EventBasicDetails = ({tournamentDetails}) => {
    const formattedFacebookLink = getFormattedURL(tournamentDetails.facebookEventLink);
    return (
        <Row className={'event-details-content-container'}>
            <Col xs={12}>
                <PageTitle title={tournamentDetails.name}/>
            </Col>
            <Col xs={12}>
                <div className={'location'}>
                    <span className={'label-info'}>Where?&nbsp;</span>
                    <span className={'period-location-info'}>{tournamentDetails.cityName}</span>
                </div>
            </Col>
            <Col xs={12} className={'basic-details-image-container'}
                 style={{backgroundImage: `url(data:image/jpeg;base64,${tournamentDetails.image})`}}>
                {/*<img src={`data:image/jpeg;base64,${userProfile.photo}`} height={'300'}/>*/}
            </Col>
            <Col xs={12}>
                <div className={'datetime'}>
                    <span className={'period-location-info'}>{getFormattedPeriod(tournamentDetails.startDate, tournamentDetails.endDate)}</span>
                    <span className={'label-info'}>When?&nbsp;</span>
                </div>
            </Col>
            <Col xs={12} className={'basic-details-information description-info'}>
                <pre>{tournamentDetails.information}</pre>
            </Col>
            {tournamentDetails.facebookEventLink &&
            <Col xs={12} className={'basic-details-information font-smaller'}>
                <a href={formattedFacebookLink} className={'facebook-link'} target={'_blank'}>
                    <Facebook size={20}/> Go to Facebook event
                </a>
            </Col>
            }
        </Row>

    )
};

const mapStateToProps = (state) => ({
    tournamentDetails: state.appReducer.tournamentDetails,
    loadingTournamentDetails: state.appReducer.loadingTournamentDetails,
    
});

const withConnect = connect(mapStateToProps)(EventBasicDetails);
export default withConnect;