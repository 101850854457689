import React, {Component, Fragment} from 'react';
import {EventInfoBanner} from 'components/EventInfoBanner';
import {PageTitle} from 'components/PageTitle';
import {Container, Col, Row} from 'react-bootstrap';
import RegisterForm from 'pages/eventDetails/register/registerForm';
import PreviewRegister from 'pages/eventDetails/register/previewRegister';
import {connect} from "react-redux";
import * as AppActions from "actions";
import moment from 'moment';
import {TIME_FORMATS} from 'constants/index';

class EventRegister extends Component {
    state = {
        femaleRegister: false,
        maleRegister: false,
        openRegisterForm: false,
        registerBody: null,
        showPreview: false
    };
    
    onOpenRegisterTeam = (categoryId) => {
        this.setState({
            maleRegister: categoryId === 1,
            femaleRegister: categoryId === 2,
            openRegisterForm: true
        });
        
        this.props.getPlayers({
            onlyUnregistered: true,
            categoryId: categoryId,
            tournamentId: this.props.event.id
        });
    };
    
    onRegisterTeam = (values) => {
        let category;
        if (this.state.femaleRegister) {
            category = 2;
        }
        if (this.state.maleRegister) {
            category = 1;
        }
        let body = {
            teamName: values.teamName,
            contact: values.contact,
            rules: values.rules,
            termsAndConditions: values.termsAndConditions,
            category: category,
            tournamentId: this.props.event.id,
            playerOneId: +values.playerOneId,
            playerTwoId: +values.playerTwoId,
            playerOneSizeId: +values.playerOneSizeId,
            playerTwoSizeId: +values.playerTwoSizeId
        };
        
        this.setState({
            registerBody: body,
            showPreview: true,
            openRegisterForm: false
        });
    };
    
    onConfirmRegister = async () => {
        await this.props.registerTeam(this.state.registerBody);
        this.setState({
            registerBody: null,
            showPreview: false,
            openRegisterForm: false,
            femaleRegister: false,
            maleRegister: false
        });
    };
    onBackToRegister = () => {
        this.setState({
            showPreview: false,
            openRegisterForm: true
        });
    };
    
    goBackToCategories=()=>{
        this.setState({
            showPreview: false,
            openRegisterForm: false,
            femaleRegister: false,
            maleRegister: false,
        });
    }
    
    render() {
        const {playersList, sizesList, citiesList, saveLoading, userProfile, event} = this.props;
        const {femaleRegister, maleRegister, openRegisterForm, showPreview, registerBody} = this.state;
        return (
            <Row>
                {event &&
                 <Fragment>
                     <Col xs={12}>
                         <EventInfoBanner event={event}/>
                         <PageTitle title={'Register'}/>
                     </Col>
                     {!event.startDateRegister && !event.endDateRegister
                      ? <Col xs={12}>
                          <div className={'event-details-content-container text-center'}>
                              Registration period is not available today!
                          </div>
                      </Col>
                      : ((event.startDateRegister && moment(event.startDateRegister).isAfter(moment(), 'date')) || (event.endDateRegister && moment(event.endDateRegister).isBefore(moment(), 'date')))
                        ? <Col xs={12}>
                            <div className={'event-details-content-container text-center'}>
                                <div className={'font-bold'}>Registration is not available today!</div>
                                {event.startDateRegister &&
                                 <div className={'font-small'}>Registration starts on: {moment(event.startDateRegister).format(TIME_FORMATS.DAY_MONTH_YEAR)}</div>
                                }
                                {event.endDateRegister &&
                                 <div className={'font-small'}>Registration ends on: {moment(event.endDateRegister).format(TIME_FORMATS.DAY_MONTH_YEAR)}</div>
                                }
                            </div>
                        </Col>
                        : <Fragment>
                            {!openRegisterForm && !showPreview &&
                                     <Col xs={12} className={'categories-container'}>
                                         <div className={'category-button-male'} onClick={() => this.onOpenRegisterTeam(1)}>
                                             <div>Register for the</div>
                                             <div className={'font-bold font-x-large'}>MEN</div>
                                             <div>tournament</div>
                                         </div>
                                         <div className={'category-button-female'} onClick={() => this.onOpenRegisterTeam(2)}>
                                             <div>Register for the</div>
                                             <div className={'font-bold font-x-large'}>WOMEN</div>
                                             <div>tournament</div>
                                         </div>
                                     </Col>
                             }
                                 {openRegisterForm &&
                                  <Col xs={12}>
                                      <div className={'event-details-content-container'}>
                                          <div className={'event-register-back-to-categories'} onClick={this.goBackToCategories}>Go back to select another category</div>
                                          <Container fluid>
                                              <RegisterForm
                                                  onRegisterTeam={this.onRegisterTeam}
                                                  playersList={playersList}
                                                  citiesOptions={citiesList}
                                                  sizesOptions={sizesList}
                                                  initialValues={registerBody && registerBody}
                                                  femaleRegister={femaleRegister}
                                                  userProfile={userProfile}
                                              />
                                          </Container>
                                      </div>
                                  </Col>
                                 }
                                 {showPreview &&
                                  <Col xs={12}>
                                      <PreviewRegister
                                          data={registerBody}
                                          playersList={playersList}
                                          citiesOptions={citiesList}
                                          sizesOptions={sizesList}
                                          femaleRegister={femaleRegister}
                                          saveLoading={saveLoading}
                                          handleBackToRegister={this.onBackToRegister}
                                          handleConfirmRegister={this.onConfirmRegister}
                                      />
                                  </Col>
                                 }
                        </Fragment>
                     }
                 </Fragment>}
            
            </Row>
        );
    }
    
    async componentDidMount() {
        
        this.props.getCities();
        this.props.getSizes();
        
    }
    
    componentWillUnmount() {
        this.props.resetPlayers();
        this.props.resetCities();
    }
}

const mapStateToProps = (state) => ({
    playersList: state.appReducer.playersList,
    sizesList: state.appReducer.sizesList,
    citiesList: state.appReducer.citiesList,
    saveLoading: state.appReducer.saveLoading,
    userProfile: state.appReducer.userProfile,
    event: state.appReducer.tournamentDetails,
    
});

const mapDispatchToProps = (dispatch) => ({
    getPlayers: (item) => dispatch(AppActions.getPlayers(item)),
    resetPlayers: () => dispatch(AppActions.resetPlayers()),
    getCities: (item) => dispatch(AppActions.getCities(item)),
    resetCities: (item) => dispatch(AppActions.resetCities(item)),
    getSizes: (item) => dispatch(AppActions.getSizes(item)),
    registerTeam: (item) => dispatch(AppActions.registerTeam(item))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EventRegister);
export default withConnect;