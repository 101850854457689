import React from 'react';
import {Formik, Field, Form,} from 'formik';
import {CustomRadioCheckComponent,CustomDropdownComponent} from 'components/RenderInput';
import {getTeamsStatusOptions} from 'utils';

const TeamsFilterForm = ({onFilterTeams}) => {
    const statusOptions = getTeamsStatusOptions();
    return (
        <Formik
            initialValues={{category: 0}}
            onSubmit={onFilterTeams}
        >
            <Form>
                <div className={'display-flex '}>
                    <div style={{paddingRight: '10px'}}>
                        <Field
                            name="status"
                            component={CustomDropdownComponent}
                            options={statusOptions}
                            label={'Status'}
                            submitOnChange
                        />
                    </div>
                    <div className={'display-flex checkbox-container'}>
                        <Field
                            name="category" type={'radio'} value={0}
                            component={CustomRadioCheckComponent}
                            label={'All'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={1}
                            component={CustomRadioCheckComponent}
                            label={'Men'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={2}
                            component={CustomRadioCheckComponent}
                            label={'Women'} submitOnChange/>
                    </div>

                </div>
            </Form>
        </Formik>

    )
};

export default TeamsFilterForm;
