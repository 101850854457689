import React from 'react';
import moment from "moment/moment";
import {PATH_URLS} from "constants/index";
import {Col} from 'react-bootstrap';
import {getFormattedPeriod} from "utils";

export const EventInfoBanner = (
    {
        event
    }
) => {
    return (
        <div className={'banner-root'}>
            <div className={'banner-container'}>{event.name}</div>
            <div className={'banner-container left-border'}>{getFormattedPeriod(event.startDate, event.endDate)}</div>
            <div className={'banner-container left-border'}>{event.cityName}</div>
        </div>
    )
};