import React from 'react';
import {Col, Button, Row} from 'react-bootstrap';
import {ArrowRightCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent, CustomInputComponent, CustomCheckboxComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";

const validate = (values, userProfile /* only available when using withFormik */) => {
    const errors = {};
    
    if (!values.teamName) {
        errors.teamName = 'Required';
    }
    if (values.rules !== true) {
        errors.rules = 'Required';
    }
    if (values.termsAndConditions !== true) {
        errors.termsAndConditions = 'Required';
    }
    if (!values.contact) {
        errors.contact = 'Required';
    }
    if (!values.playerOneId) {
        errors.playerOneId = 'Required';
    }
    if (!values.playerOneSizeId) {
        errors.playerOneSizeId = 'Required';
    }
    
    if (!values.playerTwoId) {
        errors.playerTwoId = 'Required';
    }
    if (!values.playerTwoSizeId) {
        errors.playerTwoSizeId = 'Required';
    }
    
    if (values.playerOneId && values.playerTwoId) {
        if(+values.playerOneId !== +userProfile.playerId && +values.playerTwoId !== +userProfile.playerId){
            errors.playerOneId = 'YOU must be one of the players!';
            errors.playerTwoId = 'YOU must be one of the players!';
        }
       if(+values.playerOneId === +values.playerTwoId){
            errors.playerOneId = 'Please select a different player';
            errors.playerTwoId = 'Please select a different player';
        }
        
    }
    
    
    return errors;
};

const RegisterForm = (
    {
        onRegisterTeam,
        playersList,
        citiesOptions,
        sizesOptions,
        saveLoading,
        initialValues,
        femaleRegister,
        userProfile
    }) => {
    
    const playersOptions = playersList.map(player => ({id: player.playerId, name: player.fullName}))
    return (
        <Formik
            initialValues={initialValues
                           ? initialValues
                           : {
                    rules: false,
                    termsAndConditions: false
                }}
            onSubmit={onRegisterTeam}
            validate={(values)=>validate(values, userProfile)}
        >
            <Form>
                <Row>
                    <Col xs={12} className={'font-bold font-large text-center padding-large'}>
                        {femaleRegister
                         ? 'Register for the WOMEN Tournament'
                         : 'Register for the MEN Tournament'}
                    </Col>
                    <Col xs={12} md={6} className={'padding-small'}>
                        <Field name="teamName" component={CustomInputComponent} label={'Team Name*'}/>
                    </Col>
                    <Col xs={12} md={6} className={'padding-small'}>
                        <Field name="contact" component={CustomInputComponent}
                               label={'Contact*'} placeholder={'E-mail or Phone number'}/>
                    </Col>
                    <Col xs={12} className={'padding-large text-center font-bold'}>
                        Select players
                    </Col>
                    <Col xs={12} md={6}>
                        <div className={'padding-small'}>
                            <Field name="playerOneId" component={CustomDropdownComponent}
                                   label={'Player 1*'} options={playersOptions}/>
                        </div>
                        <div className={'padding-small'}>
                            <Field name="playerOneSizeId" component={CustomDropdownComponent}
                                   label={'Player 1 T-shirt Size*'} options={sizesOptions}/>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className={'padding-small'}>
                            <Field name="playerTwoId" component={CustomDropdownComponent}
                                   label={'Player 2*'} options={playersOptions}/>
                        </div>
                        <div className={'padding-small'}>
                            <Field name="playerTwoSizeId" component={CustomDropdownComponent}
                                   label={'Player 2 T-shirt Size*'} options={sizesOptions}/>
                        </div>
                    </Col>
                
                </Row>
                
                <Row className={'checkBoxesContainer'}>
                    <Col xs={12}>
                        <Field
                            name={'rules'}
                            component={CustomCheckboxComponent}
                            label={"*We have read and we agree with the Rules of the tournament"}
                        />
                    </Col>
                    <Col xs={12}>
                        <Field
                            name={'termsAndConditions'}
                            component={CustomCheckboxComponent}
                            label={"*We have read the Terms and Conditions of the tournament"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'text-right padding-small'}>
                        <SubmitButton label={'Preview Register '} icon={<ArrowRightCircleFill size={12}/>}/>
                    
                    </Col>
                </Row>
            </Form>
        </Formik>
    
    );
};

export default RegisterForm;