import React from 'react';
import {Formik, Field, Form} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {CustomDropdownComponent} from 'components/RenderInput';
import {getYearsOptions} from 'utils/index';

const emptyFormObject = {
    year: '',
};

const RankingsFilterForm = ({tournamentsList, onSubmit, initialValues}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <Row >
                    <Col xs={6} sm={2} className={'input-container'}>
                        <Field
                            name="year"
                            component={CustomDropdownComponent}
                            options={getYearsOptions()}
                            label={'Year'}
                            submitOnChange
                            noEmptyField
                        />
                    </Col>
                   
                </Row>
            </Form>
        </Formik>
    
    );
};

export default RankingsFilterForm;
