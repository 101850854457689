import React from 'react';
import {Formik, Field, Form} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {CustomDropdownComponent} from 'components/RenderInput';

const emptyFormObject = {
    tournamentId: '',
};

const ReportsFilterForm = ({tournamentsList, onSubmit, initialValues}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <Row >
                    <Col xs={6} sm={2} className={'input-container'}>
                        <Field
                            name="tournamentId"
                            component={CustomDropdownComponent}
                            options={tournamentsList}
                            label={'Tournament'}
                            submitOnChange
                            disabled={tournamentsList && tournamentsList.length === 1}
                            noEmptyField
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    
    );
};

export default ReportsFilterForm;
