import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent, CustomInputComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";
import {getCategoryOptions} from 'utils';

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    
    if (!values.teamId) {
        errors.teamId = 'Required';
    }
    if (!values.points) {
        errors.points = 'Required';
    }else if(values.points <=0){
        errors.points = 'Points must be a positive value'
    }
    
    if (!values.place) {
        errors.place = 'Required';
    } else if(values.place <=0){
        errors.place = 'The place must be a positive value'
    }
    
    return errors;
};

const emptyFormObject = {
    teamId: '',
    points: '',
    place: '',
};

const RankingsForm = ({onSubmit, initialValues, schedulesList, teamsList, saveLoading}) => {
    const categoryOptions = getCategoryOptions();
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
            validate={validate}
        >
            <Form>
                <Row>
                    <Col xs={12} sm={9} className={'padding-small'}>
                        <Field
                            name="teamId"
                            component={CustomDropdownComponent}
                            options={teamsList}
                            label={'Team'}
                        />
                    </Col>
                    <Col xs={12} sm={3} className={'padding-small'}>
                        <Field
                            name={initialValues?.tournamentRankingId ? 'gender':'category'}
                            disabled
                            component={CustomDropdownComponent}
                            options={categoryOptions}
                            label={'Category'}
                        />
                    </Col>
                    <Col xs={6} sm={3} className={'padding-small'}>
                        <Field name="place" component={CustomInputComponent} label={'Place'}/>
                    </Col>
                    
                    <Col xs={6} sm={3} className={'padding-small'}>
                        <Field
                            name="points"
                            component={CustomInputComponent}
                            label={'Points'}
                        />
                    </Col>
                </Row>
                <Row>
                   
                    <Col xs={12} className={'text-right padding-small'}>
                        {saveLoading
                         ? <SpinnerButton label={'Saving'}/>
                         : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>
    
    )
};

export default RankingsForm;
