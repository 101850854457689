import React from 'react';
import {getCalculatedNumberOfTeams} from 'utils/index';


function NumberOfTeamsDetails({tournament}) {
    const menTeamsDetails = getCalculatedNumberOfTeams(tournament.numberOfMenTeamsAllowed, tournament.numberOfMenTeams);
    const womenTeamsDetails = getCalculatedNumberOfTeams(tournament.numberOfWomenTeamsAllowed, tournament.numberOfWomenTeams);
    
    return (
        <>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                <div>
                    <div className={menTeamsDetails.isFullDraw ? 'display-flex align-items-center color-success font-bold' : 'display-flex align-items-center font-bold'}>
                        M: {menTeamsDetails.allowed} teams
                    </div>
                    {menTeamsDetails.isFullDraw
                     ? <div className={'font-x-small color-success'}>List complete</div>
                     : <div className={'font-x-small'}>{menTeamsDetails.availableSpotsOnDraw} still available </div>
                    }
                </div>
                <div>
                    <div className={womenTeamsDetails.isFullDraw ? 'display-flex align-items-center color-success font-bold' : 'display-flex align-items-center font-bold'}>
                        W: {tournament.numberOfWomenTeamsAllowed} teams
                    </div>
                    {womenTeamsDetails.isFullDraw
                     ? <div className={'font-x-small color-success'}>List complete</div>
                     : <div className={'font-x-small'}>{womenTeamsDetails.availableSpotsOnDraw} still available </div>
                    }
                </div>
            </div>
        </>
    );
}

export default NumberOfTeamsDetails;