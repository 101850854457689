import React from 'react';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent, CustomRadioCheckComponent} from 'components/RenderInput';

const emptyFormObject = {
    category: '',
    cityId: ''
};

const PlayersFilterForm = ({onSubmit, initialValues, citiesOptions}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <div className={'display-flex '}>
                    <div style={{paddingRight: '10px'}}>
                        <Field
                            name="cityId"
                            component={CustomDropdownComponent}
                            options={citiesOptions}
                            label={'City'}
                            submitOnChange
                        />
                    </div>
                    <div className={'display-flex checkbox-container'}>
                        <Field
                            name="category" type={'radio'} value={0}
                            component={CustomRadioCheckComponent}
                            label={'All'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={1}
                            component={CustomRadioCheckComponent}
                            label={'Men'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={2}
                            component={CustomRadioCheckComponent}
                            label={'Women'} submitOnChange/>
                    </div>
                </div>
            </Form>
        </Formik>
    
    );
};

export default PlayersFilterForm;
