import React, {Fragment} from 'react';
import Table from 'react-bootstrap/Table';
import {Popover, OverlayTrigger, Button, ButtonGroup, DropdownButton, Dropdown} from 'react-bootstrap';
import _ from 'lodash';
import {ThreeDotsVertical, Pencil, Trash} from 'react-bootstrap-icons';
import {Loader} from "components/Loader";

const mapTableDataBySortProperty = (item, header) => {
    const mapProperties = property => {
        
        if (property.map) {
            return property.map(item);
        }
        if (property.property) {
            return _.get(item, property.property);
        }
        if (property.display) {
            return _.get(item, property.display);
        }
        return _.get(item, property);
    };
    
    if (Array.isArray(header)) {
        return header.map(mapProperties);
    }
    return header.data.map(mapProperties);
    
};

export const TableComponent = (
    {
        header,
        data,
        indexColumn,
        editable,
        removable,
        onEditItem,
        onDeleteItem,
        loading,
        noDataMessage = 'No data to display',
        noCollapsing = false,
        otherButtons,
        otherIndependentButtons
    }
) => {
    let w = window.innerWidth;
    let collapseTable = w < 575 && !noCollapsing;
    
    if (loading) {
        return <Loader/>;
    } else if (data && data.length > 0) {
        if (collapseTable) {
            return (
                <>
                    <div className={'font-small color-primary text-right'}>Results: {data.length} lines</div>
                    <Table striped bordered className={'custom-table'}>
                        <tbody>
                        {data && data.length > 0 && data.map((dataItem, index) => {
                            let displayData = mapTableDataBySortProperty(dataItem, header);
                            return (
                                <tr key={index} className={'collapsed-table-row'}>
                                    <td>
                                        {indexColumn &&
                                         <div className={'collapsed-table-data-row'}>
                                             <div># {index + 1}</div>
                                         </div>
                                        }
                                        {header.map((item, index) => {
                                            let displayValue;
                                            if (typeof displayData[index] === 'object') {
                                                displayValue = displayData[index].display;
                                            } else {
                                                displayValue = displayData[index];
                                            }
                                            return (
                                                <div key={index} className={'collapsed-table-data-row'}>
                                                    <div className={item.emphasizedColumn ? 'font-bold font-small' : 'font-small'}>
                                                        {item.display}
                                                    </div>
                                                    <div className={item.emphasizedColumn ? 'font-bold' : ''}>
                                                        {displayValue}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {(editable || removable || otherButtons || otherIndependentButtons) &&
                                         <div className={'display-flex justify-space-between'}>
                                             {otherIndependentButtons && otherIndependentButtons.map((button, index) => (
                                                 <div key={index} className={'popover-item'}
                                                      onClick={() => button.action(dataItem)}>
                                                     {button.icon}
                                                 </div>
                                             ))}
                                             {removable &&
                                              <div className={'popover-item'}
                                                   onClick={() => onDeleteItem(dataItem)}>
                                                  <Trash color={'#961329'}/>
                                              </div>
                                             }
                                             {editable &&
                                              <div className={'popover-item'}
                                                   onClick={() => onEditItem(dataItem)}>
                                                  <Pencil/>
                                              </div>
                                             }
                                             {otherButtons && otherButtons.map((button, index) => (
                                                 <div key={index} className={'popover-item'}
                                                      onClick={() => button.action(dataItem)}>
                                                     {button.icon}
                                                 </div>
                                             ))}
                                         </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </>
            );
        }
        return (
            <>
                <div className={'font-small color-primary text-right'}>Results: {data.length} lines</div>
                <Table striped bordered hover className={'custom-table'}>
                    <thead>
                    <tr>
                        {indexColumn &&
                         <th>#</th>
                        }
                        {header.map((item, index) => (
                            <th key={index}>{item.display}</th>
                        ))}
                        {(editable || removable) &&
                         <th>
                         </th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.length > 0 && data.map((dataItem, index) => {
                        let displayData = mapTableDataBySortProperty(dataItem, header);
                        return (
                            <tr key={index}>
                                {indexColumn &&
                                 <td>{index + 1}</td>
                                }
                                {displayData.map((value, index) => {
                                    let displayValue;
                                    if (typeof value === 'object') {
                                        displayValue = value.display;
                                    } else {
                                        displayValue = value;
                                    }
                                    return (
                                        <td key={index}>{displayValue}</td>
                                    );
                                })}
                                {(editable || removable || otherButtons || otherIndependentButtons) &&
                                 <td className={'display-flex justify-end'}>
                                     {otherIndependentButtons && otherIndependentButtons.map((button, index) => (
                                         <div
                                             key={index}
                                             onClick={() => button.action(dataItem)}>
                                             {button.icon}
                                         </div>
                                     ))}
                                     {(editable || removable || otherButtons) &&
                                      <OverlayTrigger
                                          rootClose={true}
                                          delay={{show: 0, hide: 400}}
                                          trigger="click" placement="left"
                                          overlay={
                                              <Popover id="popover-basic">
                                                  <Popover.Content>
                                                      {otherButtons && otherButtons.map((button, index) => (
                                                          <div key={index} className={'popover-item'}
                                                               onClick={() => button.action(dataItem)}>
                                                              {button.icon} {button.label}
                                                          </div>
                                                      ))}
                                                      {editable &&
                                                       <div className={'popover-item'}
                                                            onClick={() => onEditItem(dataItem)}>
                                                           <Pencil/> Update
                                                       </div>
                                                      }
                                                      {removable &&
                                                       <div className={'popover-item'}
                                                            onClick={() => onDeleteItem(dataItem)}>
                                                           <Trash/> Remove
                                                       </div>
                                                      }
                                                  </Popover.Content>
                                              </Popover>
                                          }>
                                          <div className={'cursor-pointer'}>
                                              <ThreeDotsVertical/>
                                          </div>
                                      </OverlayTrigger>
                                     }
                                 </td>
                                }
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </>
        );
    } else {
        return <div className={'no-data-message'}>{noDataMessage}</div>;
    }
};
