import React, {Fragment} from 'react';
import {TableComponent} from "components/Table";
import {Loader} from "components/Loader";

const PlayerHistoryList = ({loading, playerHistory}) => {
    return (
        <Fragment>
            {loading
                ? <Loader/>
                : <TableComponent
                    data={playerHistory}
                    header={header}
                />
            }
        </Fragment>
    )
};

export default PlayerHistoryList;

const header = [
    {
        display: 'Tournament',
        property: 'tournamentName',
        map: (item, index) =>{
            if(item.teamName){
                return({
                    display: <div key={index}>
                        <div>{item.tournamentName}</div>
                        <div className={'font-small'}>{item.teamName} | {item.playerTwoName}</div>
                    </div>
                })
            }
            else {
                return ({
                    display: <div key={index}>{item.tournamentName}</div>
                })
            }
        }
    },
    {display: 'Year', property: 'tournamentYear'},
    {
        display: 'Place', property: 'place',
        map: ((item, index) => {
                let firstThree = item.place === 1 || item.place === 2 || item.place === 3;
                return ({
                    display: <div key={index}
                                  className={firstThree ? 'font-bold' : ''}
                    >
                        {item.place}
                    </div>
                })
            }
        )
    },
    {display: 'Points', property: 'points'},

];