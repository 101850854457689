import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent, CustomRadioCheckComponent} from 'components/RenderInput';

const emptyFormObject = {
    tournamentId: '',
    category: '',
};

const GamesFilterForm = ({onSubmit, initialValues, tournamentsList, teamsList, schedulesList}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <Row >
                    <Col xs={6} sm={2} className={'input-container'}>
                        <Field
                            name="tournamentId"
                            component={CustomDropdownComponent}
                            options={tournamentsList}
                            label={'Tournament'}
                            submitOnChange
                            disabled={tournamentsList && tournamentsList.length === 1}
                            noEmptyField
                        />
                    </Col>
                    <Col xs={6} sm={2} className={'input-container'}>
                        <Field
                            name="scheduleId"
                            component={CustomDropdownComponent}
                            options={schedulesList}
                            label={'Schedule'}
                            submitOnChange
                        />
                    </Col>
                    <Col xs={6} sm={2} className={'input-container'}>
                        <Field
                            name="teamId"
                            component={CustomDropdownComponent}
                            options={teamsList}
                            label={'Team'}
                            submitOnChange
                        />
                    </Col>
                    <Col xs={7} sm={4} className={'display-flex checkbox-container'}>
                        <Field
                            name="category" type={'radio'} value={0}
                            component={CustomRadioCheckComponent}
                            label={'All'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={1}
                            component={CustomRadioCheckComponent}
                            label={'Men'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={2}
                            component={CustomRadioCheckComponent}
                            label={'Women'} submitOnChange/>
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
};

export default GamesFilterForm;
