import React from 'react';
import {Col} from 'react-bootstrap';
import {getSplitTitle} from 'utils/index';

export const PageTitle = (
    {
        title
    }) => {
    return (
        <Col xs={12} className={'page-title'}>
            <div className={'page-title-main'}>{getSplitTitle(title).title}</div>
            <div className={'page-title-subtitle'}>{getSplitTitle(title).subtitle}</div>
        </Col>
    )
};