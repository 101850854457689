import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form, withFormik} from 'formik';
import {CustomDatePickerComponent, CustomDropdownComponent, CustomInputComponent, CustomTextAreaComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.tournamentID) {
        errors.tournamentID = 'Required';
    }
    if (!values.date) {
        errors.date = 'Required';
    }
    
    return errors;
};

const emptyFormObject = {
    name: '',
    date: '',
    tournamentId: '',
};

const SchedulesForm = ({onSubmit, initialValues, tournamentsList, saveLoading}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
            validate={validate}
        >
            <Form>
                <Row>
                    <Col xs={12} sm={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className={'padding-small'}>
                        <Field
                            name="tournamentID"
                            component={CustomDropdownComponent}
                            options={tournamentsList}
                            label={'Tournament'}
                            disabled
                            noEmptyField
                        />
                    </Col>
                    <Col xs={12} sm={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className={'padding-small'}>
                        <Field name="name" component={CustomInputComponent} label={'Name'}/>
                    </Col>
                    
                    <Col xs={12} sm={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className={'padding-small'}>
                        <Field
                            name="date"
                            component={CustomDatePickerComponent}
                            label={'Start Date'}
                        />
                    </Col>

                    <Col xs={12} className={'text-right padding-small'}>
                        {saveLoading
                            ? <SpinnerButton label={'Saving'}/>
                            : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>

    )
};

export default SchedulesForm;
