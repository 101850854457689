import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {ArrowRightCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form,} from 'formik';
import {CustomInputComponent} from 'components/RenderInput';
import {BackButton, SpinnerButton, SubmitButton} from "components/Buttons";

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Required';
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
    }
    if (!values.oldPassword) {
        errors.oldPassword = 'Required';
    }
    if(values.password && values.confirmPassword && values.password !==values.confirmPassword){
        errors.confirmPassword = 'The password does not match!';
    }

    return errors;
};

export const ChangePasswordForm = ({onSavePassword, loading, onCancelChangePasswordForm}) => {
    return (
        <Formik
            initialValues={{
                oldPassword: '',
                password: '',
                confirmPassword: '',
            }}
            onSubmit={onSavePassword}
            validate={validate}
            onCancelChangePasswordForm={onCancelChangePasswordForm}
        >
            <Form>
                <Row>
                    <Col xs={12} className={'padding-small'}>
                        <Field name="oldPassword" component={CustomInputComponent} label={'Current Password'}/>
                    </Col>
                    <Col xs={12} className={'padding-small'}>
                        <Field name="password" type={'password'} component={CustomInputComponent}
                               label={'New Password'}/>
                    </Col>
                    <Col xs={12} className={'padding-small'}>
                        <Field name="confirmPassword" type={'password'} component={CustomInputComponent}
                               label={'Confirm Password'}/>
                    </Col>
                    <Col xs={12} className={'text-center padding-small'}>
                        <BackButton label={'Back'} onClick={onCancelChangePasswordForm}/>
                        {loading
                            ? <SpinnerButton label={'Saving '}/>
                            : <SubmitButton label={'Save password '} icon={<ArrowRightCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>

    )
};