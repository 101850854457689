import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Search, XOctagon, ExclamationDiamondFill} from 'react-bootstrap-icons';

export const PageNotFound = () => {
    return (
        <Container className={'display-flex justify-center align-center'}>
            <Row>

                <Col xs={12} className={'display-flex justify-center text-center font-xx-large'}>
                    404
                </Col>
                <Col xs={12} className={'display-flex justify-center text-center font-xx-large'}>
                    Page Not Found
                </Col>
                <Col xs={12} className={'display-flex justify-center text-center'}>
                    <ExclamationDiamondFill size={50}/>
                </Col>
            </Row>
        </Container>
        // <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //     <Row >
        //
        //         <Col xs={12}
        //              style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 'xx-large', textAlign: 'center'}}>
        //             404 <br/>
        //             Page Not Found
        //         </Col>
        //         <Col xs={12}
        //              style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 'xx-large', textAlign: 'center'}}>
        //             <XOctagon size={60}/>
        //         </Col>
        //     </Row>
        // </Container>
    )
};