import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {ArrowRight} from 'react-bootstrap-icons';
import {ADMIN_MENU_CATEGORY} from "constants/index";
import {PageTitle} from 'components/PageTitle';
import {NavLink} from 'react-router-dom';
import {routes} from 'config/routes';
import {connect} from 'react-redux';

class AdminProfile extends Component {
    render() {
        const {isLoggedIn} = this.props;
        return (
            <Container fluid>
                <PageTitle title={'Admin Dashboard'}/>
                <Row className={'admin-panel-container'}>
                    
                    {Object.keys(ADMIN_MENU_CATEGORY).map((key, index) => {
                        let category = ADMIN_MENU_CATEGORY[key];
                        return (
                            <Col key={index} xs={12} md={4} className={'admin-panel-section'}>
                                <div className={'admin-section-header'}>{category}</div>
                                {routes.map((route, index) => {
                                    if (route.showInMenu && route.menu === category && route.isAvailable(isLoggedIn)) {
                                        return (<div key={index}>
                                                <NavLink to={route.path} className={'color-primary'}>
                                                    <ArrowRight/>&nbsp;
                                                    {route.pageTitle}
                                                </NavLink>
                                            </div>
                                        );
                                    }
                                })}
                            </Col>
                        );
                    })}
                    
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.appReducer.isLoggedIn
});

const withConnect = connect(mapStateToProps)(AdminProfile);
export default withConnect;