import { PATH_URLS, SIDEBAR_ITEM_KEYS } from "constants/index";
import moment from "moment";
import React from "react";
import { CalendarWeekFill, GeoAltFill } from "react-bootstrap-icons";
import { getFormattedPeriod } from "utils/index";
import NumberOfTeamsDetails from "pages/home/NumberOfTeamsDetails";
import RegistrationStatus from "pages/home/RegistrationStatus";

const TournamentList = ({ tournament, year }) => {
  const getIsActiveClassName = (start, end) => {
    if (moment().isBefore(moment(start))) {
      return "home-event-future";
    } else if (moment().isAfter(moment(end))) {
      return "home-event-past";
    }
    return "home-event-ongoing";
  };

  return (
    <>
      <div className={"home-year-header"}>{year}</div>
      <div className={"home-events-grid"}>
        {tournament.map((tournament) => {
          let isActiveClassName = getIsActiveClassName(
            tournament.startDate,
            tournament.endDate
          );
          return (
            <a
              key={tournament.id}
              href={`${PATH_URLS.EVENT}/${tournament.id}${SIDEBAR_ITEM_KEYS.DETAILS}`}
            >
              <div className={`home-event-card ${isActiveClassName}`}>
                <div
                  className={"home-event-photo"}
                  style={{
                    backgroundImage: tournament.smallImage
                      ? `url(data:image/jpeg;base64,${tournament.smallImage})`
                      : `url(${"beach_volleyball_event_img.jpg"})`,
                  }}
                ></div>
                <div className={`home-event-details`}>
                  <div className={"home-event-detail"}>
                    <CalendarWeekFill size={12} />
                    &nbsp;&nbsp;
                    {getFormattedPeriod(
                      tournament.startDate,
                      tournament.endDate
                    )}
                  </div>
                  <div className={"home-event-title"}>{tournament.name}</div>
                  <div className={"home-event-detail"}>
                    <GeoAltFill size={12} />
                    &nbsp;&nbsp;
                    {tournament.cityName}
                  </div>
                </div>
                <RegistrationStatus tournament={tournament} />
                <div className={"home-event-footer"}>
                  <NumberOfTeamsDetails tournament={tournament} />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </>
  );
};

export default TournamentList;
