import React from 'react';
import {TableComponent} from 'components/Table';

const header = [
    {
        display: 'Tournament', property: 'tournamentName',
        map: (item, index) =>{
            if(item.teamName){
                return({
                    display: <div key={index}>
                        <div>{item.tournamentName}</div>
                        <div className={'font-small'}>{item.teamName} | {item.playerTwoName}</div>
                    </div>
                })
            }
            else {
                return ({
                    display: <div key={index}>{item.tournamentName}</div>
                })
            }
        }},
    {display: 'Year', property: 'tournamentYear'},
    {display: 'Place', property: 'place'},
    {display: 'Points', property: 'points'}

];

const HistoryDetailsModal = ({playerHistory, loading}) => {
    
    return (
        <div className={'history-modal-container'}>
            <TableComponent
                data={playerHistory}
                header={header}
                loading={loading}
            />
        </div>
    );
};

export default HistoryDetailsModal;