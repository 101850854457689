import React, {Component} from 'react';
import {Col, Button, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form, withFormik} from 'formik';
import {CustomDropdownComponent, CustomInputComponent, CustomTextAreaComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";
import ImageUploader from "components/ImageUploader/index";

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    
    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName = 'Required';
    }
    if (!values.cityId) {
        errors.cityId = 'Required';
    }
    if (!values.sizeId) {
        errors.sizeId = 'Required';
    }
    
    
    return errors;
};

const emptyFormObject = {
    firstName: '',
    lastName: '',
    sizeId: '',
    cityId: '',
    weight: '',
    height: '',
    birthdate: ''
    
};

class UserProfileForm extends Component {
    editorRef = React.createRef();
    
    onFilesUploaded = async (values) => {
        // console.log(' this.editorRef.current.getImage()',  this.editorRef.current.getImage().toDataURL('image/png', 1.0))
        if (this.editorRef.current) {
            this.props.onSubmit({
                ...values,
                photo: this.editorRef.current.getImage().toDataURL('image/png', 1.0).replace('data:image/png;base64,', '')
            });
        }
        else {
            this.props.onSubmit({...values, photo: null});
        }
    };
    
    render() {
        const {onSubmit, initialValues, citiesOptions, saveLoading, sizesOptions} = this.props;
        return (
            <Formik
                initialValues={initialValues || emptyFormObject}
                onSubmit={onSubmit}
                validate={validate}
            >
                <Form>
                    <Row>
                        <Col xs={6} xl={3} className={'padding-small'}>
                            <Field name="firstName" component={CustomInputComponent} label={'First Name*'}/>
                        </Col>
                        <Col xs={6} xl={3} className={'padding-small'}>
                            <Field name="lastName" component={CustomInputComponent} label={'Last Name*'}/>
                        </Col>
                        <Col xs={6} xl={3} className={'padding-small'}>
                            <Field
                                name="cityId"
                                component={CustomDropdownComponent}
                                options={citiesOptions}
                                label={'City*'}
                            />
                        </Col>
                        <Col xs={6} xl={3} className={'padding-small'}>
                            <Field name="birthdate" component={CustomInputComponent} type={'date'}
                                   label={'Birth Date'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} className={'padding-small'}>
                            <Field
                                name="sizeId"
                                component={CustomDropdownComponent}
                                options={sizesOptions}
                                label={'T-shirt Size*'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} className={'padding-small'}>
                            <Field name="height" component={CustomInputComponent} label={'Height (cm)'}
                                   type={'number'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} className={'padding-small'}>
                            <Field name="weight" component={CustomInputComponent} label={'Weight (kg)'}  type={'number'}/>
                        </Col>
                    
                    </Row>
                    
                    <Row>
                        <Col xs={12} className={'text-right padding-small'}>
                            {saveLoading
                             ? <SpinnerButton label={'Saving'}/>
                             : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>}/>
                            }
                        </Col>
                    </Row>
                </Form>
            </Formik>
        
        );
    }
}

export default UserProfileForm;
