import React from 'react';
import {Button, Spinner} from 'react-bootstrap';
import {FileEarmarkArrowDown} from 'react-bootstrap-icons'

export const SubmitButton = ({label, type = 'submit', onClick, icon, disabled}) =>
    <Button className={'submit-btn'} type={type} onClick={onClick} disabled={disabled}>
        {label}&nbsp;
        {icon && icon}
    </Button>;

    export const SpinnerButton = ({label}) =>
        <Button className={'submit-btn'} disabled>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />&nbsp;
            {label}...
        </Button>;

export const BackButton = ({label, type = 'button', onClick}) =>
    <Button className={'back-btn'} type={type} onClick={onClick}>{label}</Button>;

export const AddButton = ({label = 'Add New', type = 'button', onClick, icon}) =>
    <Button className={'add-btn'} type={type} onClick={onClick}>
        {label}&nbsp;
        {icon && icon}
    </Button>;

export const RemoveButton = ({label, type = 'button', onClick, icon}) =>
    <Button className={'remove-btn'} type={type} onClick={onClick}>
        {label && `${label} `}
        {icon && icon}
    </Button>;
    
export const DownloadButton = ({label, type = 'button', onClick, icon}) =>
    <Button className={'download-btn'} type={type} onClick={onClick}>
        {label && `${label}`}
        &nbsp;
        <FileEarmarkArrowDown size={15}/>
    </Button>;