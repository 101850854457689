import React from 'react';
import {ChangePasswordForm} from "components/ChangePasswordForm";
import {PageTitle} from "components/PageTitle";
import {connect} from "react-redux";
import {Col, Container, Row} from 'react-bootstrap';
import * as AppActions from "actions";
import {ModalComponent} from "components/Modal";

const ChangePasswordPage = (
    {
        loading,
        login,
        resetPassword,
        userProfile,
        logout
    }) => {

    let onResetPassword = (credentials) =>{
        resetPassword({
            userId: userProfile.userId,
            oldPassword: credentials.oldPassword,
            password: credentials.password
        })
    };
    return (
        <Col className={'page-container'}>
            {/*<Row>*/}
                {/*<PageTitle title={"Change  your password"}/>*/}
                {/*<Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}*/}
                     {/*className={'contact-container container-with-flag'}>*/}
                    {/*<Container>*/}
                        {/*<ChangePasswordForm onSavePassword={onResetPassword} loading={loading} onCancelChangePasswordForm={logout}/>*/}
                    {/*</Container>*/}
                {/*</Col>*/}
            {/*</Row>*/}
            <Row>
                <ModalComponent
                    show={true}
                onHide={logout}
                title={'Change your password'}
                >
                    <div>Please choose another password for your account!</div>
                    <ChangePasswordForm onSavePassword={onResetPassword} loading={loading} onCancelChangePasswordForm={logout}/>
                </ModalComponent>
            </Row>
        </Col>
    )
};

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading,
    userProfile: state.appReducer.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (credentials) => dispatch(AppActions.resetPassword(credentials)),
    logout: () => dispatch(AppActions.logout()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
export default withConnect;