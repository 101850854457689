import React from 'react';
import moment from 'moment';

function RegistrationStatus({tournament}) {
    const startRegister = tournament.startDateRegister;
    const endRegister = tournament.endDateRegister;
    let registerOpen = false;
    let registerClosed = false;
    
    if (!startRegister && !endRegister) {
        registerOpen = true;
    } else if (startRegister && !endRegister && moment().isSameOrAfter(moment(startRegister), 'date')) {
        registerOpen = true;
    } else if (startRegister && endRegister && moment().isSameOrAfter(moment(startRegister), 'date') && moment().isSameOrBefore(moment(endRegister), 'date')) {
        registerOpen = true;
    } else if (endRegister && moment().isAfter(moment(endRegister), 'date')) {
        registerOpen = false;
        registerClosed = true;
    }
    return (
        <div className={registerOpen
                        ? 'home-event-registration-status-open'
                        : registerClosed
                          ? 'home-event-registration-status-closed'
                          : 'home-event-registration-status'}>
            {registerOpen
             ? 'Registration OPEN'
             : registerClosed
               ? 'Registration CLOSED'
               : 'Registration not available'}
        </div>
    );
}

export default RegistrationStatus;