export const readInputFile = (inputFile) => {
    const fileReader = new FileReader();
    
    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject('Error while reading file');
        };
        
        fileReader.onloadend = (readEndEvent) => {
            const result = readEndEvent.target.result;
            
            // at the beginning we have application type info that needs to be removed
            const fileData = result.substr(result.indexOf(',') + 1, result.length);
            const fileName = inputFile.name;
            const fileType = inputFile.type;
            
            const fileInfo = {
                fileName,
                fileType,
                fileData,
            };
            
            resolve(fileInfo);
        };
        
        !!inputFile && fileReader.readAsDataURL(inputFile);
    });
};