import React from 'react';
import {Formik, Field, Form} from 'formik';
import {CustomRadioCheckComponent} from 'components/RenderInput';

const emptyFormObject = {
    category: 1,
    cityId: ''
};

const RankFilterForm = ({onSubmit, initialValues}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <div className={'display-flex justify-content-center'}>
                    <Field
                        name="category" type={'radio'} value={1}
                        component={CustomRadioCheckComponent}
                        label={'Men'} submitOnChange/>
                    <Field
                        name="category" type={'radio'} value={2}
                        component={CustomRadioCheckComponent}
                        label={'Women'} submitOnChange/>
                </div>
            </Form>
        </Formik>
    
    );
};

export default RankFilterForm;
