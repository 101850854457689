import React from 'react';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent} from 'components/RenderInput';

const emptyFormObject = {
    tournamentId: '',
};

const SchedulesFilterForm = ({onSubmit, initialValues, tournamentsList}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <div className={'display-flex '}>
                    <div style={{paddingRight: '10px'}}>
                        <Field
                            name="tournamentId"
                            component={CustomDropdownComponent}
                            options={tournamentsList}
                            label={'Tournament'}
                            submitOnChange
                            noEmptyField
                            disabled={tournamentsList && tournamentsList.length === 1}
                        />
                    </div>
                </div>
            </Form>
        </Formik>
    
    )
};

export default SchedulesFilterForm;
