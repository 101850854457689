import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {ArrowRightCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent, CustomInputComponent} from 'components/RenderInput';
import {SpinnerButton, SubmitButton} from "components/Buttons";
import {GENDER_CATEGORY_OPTIONS} from 'constants/index';

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName = 'Required';
    }
    if (!values.cityId) {
        errors.cityId = 'Required';
    }
    if (!values.gender) {
        errors.gender = 'Required';
    }
    if (!values.username) {
        errors.username = 'Required';
    }
    if (!values.email) {
        errors.email = 'Required';
    }
    if (!values.password) {
        errors.password = 'Required';
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = 'Please confirm your password!';
    }
    if(values.password && values.confirmPassword && values.password !== values.confirmPassword){
        errors.confirmPassword = 'Passwords do not match!'
    }
    
    return errors;
};
const emptyFormObject = {
    firstName: '',
    lastName: '',
    cityId: '',
    username: '',
    password: '',
    photo: '',
    gender:'',
    email:''
};

class RegisterForm extends React.Component {
    editorRef = React.createRef();
    
    onFilesUploaded = async (values) => {
        if (this.editorRef.current) {
            this.props.onSubmit({
                ...values,
                photo: this.editorRef.current.getImage().toDataURL('image/png', 1.0).replace('data:image/png;base64,', '')
            });
        }
        else {
            this.props.onSubmit({...values, photo: null});
        }
    };
    
    render() {
        const {initialValues, loading, citiesOptions, onSubmit} = this.props;
        return (
            <Formik
                initialValues={initialValues || emptyFormObject}
                onSubmit={onSubmit}
                validate={validate}
            >
                <Form>
                    <Row>
                        <Col xs={12} md={{span: 8, offset: 2}} xl={{span: 6, offset:3}} >
                            <div className={'padding-small'} >
                                <Field name="firstName" component={CustomInputComponent} label={'First Name'}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="lastName" component={CustomInputComponent} label={'Last Name'}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="cityId" component={CustomDropdownComponent} label={'City'} options={citiesOptions}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="gender" component={CustomDropdownComponent} label={'Gender'} options={GENDER_CATEGORY_OPTIONS}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="email" component={CustomInputComponent} label={'E-mail'}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="username" component={CustomInputComponent} label={'Username'}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="password" type={'password'} component={CustomInputComponent}
                                       label={'Password'}/>
                            </div>
                            <div className={'padding-small'}>
                                <Field name="confirmPassword" type={'password'} component={CustomInputComponent}
                                       label={'Confirm Password'}/>
                            </div>
                        </Col>
                        
                        <Col xs={12} className={'text-center padding-small'}>
                            {loading
                             ? <SpinnerButton label={'Signing up'}/>
                             : <SubmitButton label={'Sign up '} icon={<ArrowRightCircleFill size={12}/>}/>
                            }
                        </Col>
                    </Row>
                </Form>
            </Formik>
        
        );
    }
};

export default RegisterForm;