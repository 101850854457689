import React from 'react';
import {NavLink} from 'react-router-dom';
import {PATH_URLS} from "constants/index";
import {eventRoutes} from "config/routes";
import {withRouter} from 'react-router-dom';


const EventSidebar = (
    {
        closeSidebar,
        openSideBar,
        eventId,
        isLoggedIn,
        onSelectMenuItem
    }
) => {
    
    return (
        <div className={openSideBar
                        ? 'sidebar openedSidebar'
                        : 'sidebar closedSidebar'}
        >
            <a href="javascript:void(0)" className={'closebtn'} onClick={closeSidebar}>&times;</a>
            
            {eventRoutes.map((route, index) => {
                if (route.showInMenu && route.isAvailable(isLoggedIn)) {
                    return (
                        <div key={index}>
                            <NavLink
                                to={`${PATH_URLS.EVENT}/${eventId}${route.path}`}
                                className={'sidebar-item-inactive'}
                                activeClassName={'sidebar-item-active'}
                                onClick={onSelectMenuItem}
                            >
                                <div className={'sidebar-item '}>
                                    {route.pageTitle}
                                </div>
                            </NavLink>
                        </div>
                    );
                }
            })}
        </div>
    );
};
export default withRouter(EventSidebar);
