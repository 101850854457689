import React from 'react';
import {connect} from 'react-redux';
// import {PortalComponent, SegmentComponent, TransitionGroupComponent} from 'components/SemanticComponents';
import {NOTIFICATION_DEFAULT_TIMEOUT, NOTIFICATION_ANIMATION} from 'constants/index';
import * as AppActions from "actions";
import {Toast} from 'react-bootstrap';
import {CheckCircleFill, ExclamationCircleFill, XCircleFill} from 'react-bootstrap-icons';

const NotificationPortal = ({messages, removeNotification}) => {
    return (
        <div className={'notifications-container'}>
            {messages && messages.map((message, index) => {
                if (message.timeout === undefined || message.timeout === null) {
                    message.timeout = setTimeout(() => {
                        removeNotification(message.id);
                    }, NOTIFICATION_DEFAULT_TIMEOUT);
                }
                const deleteNotification = () => {
                    clearTimeout(message.timeout);
                    removeNotification(message.id);
                };
                return (
                    <div key={index} className={'notification-content'}>
                        {message.iconType === 'success' &&
                        <CheckCircleFill color={message.iconColor} size={30} className={'notification-icon'}/>
                        }
                        {message.iconType === 'warning' &&
                        <ExclamationCircleFill color={message.iconColor} size={30} className={'notification-icon'}/>
                        }
                        {message.iconType === 'error' &&
                        <XCircleFill color={message.iconColor} size={30} className={'notification-icon'}/>
                        }
                        <Toast
                            onClose={() => deleteNotification()}
                            show={true}
                            delay={NOTIFICATION_DEFAULT_TIMEOUT}
                            autohide
                            className={'notification-toast'}

                        >
                            <Toast.Header className={`notification-segment ${message.className}`}>
                                <strong className={'mr-auto notification-message'}>{message.message}</strong>
                            </Toast.Header>

                        </Toast>
                    </div>
                )
            })}
        </div>
    );
};

const mapStateToProps = (state) => ({
    messages: state.appReducer.messages,
});

const mapDispatchToProps = (dispatch) => ({
    notifySuccess: (message) => dispatch(AppActions.notifySuccess(message)),
    notifyWarning: (message) => dispatch(AppActions.notifyWarning(message)),
    notifyError: (message) => dispatch(AppActions.notifyError(message)),
    removeNotification: (notificationId) => dispatch(AppActions.removeNotification(notificationId)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(NotificationPortal);

export default withConnect;