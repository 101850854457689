import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {ArrowRightCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form,} from 'formik';
import {CustomInputComponent} from 'components/RenderInput';
import {BackButton, SpinnerButton, SubmitButton} from "components/Buttons";
import {PATH_URLS} from 'constants/index';

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    
    if (!values.email) {
        errors.email = 'Required';
    }
    
    return errors;
};

export const ForgotPasswordForm = ({onSendEmail, loading, switchRoute}) => {
    return (
        <Formik
            initialValues={{
                email: '',
            }}
            onSubmit={onSendEmail}
            validate={validate}
        >
            <Form>
                <Row>
                    <Col xs={12} className={'padding-small'}>
                        <Field name="email" component={CustomInputComponent} label={'Email Address'}/>
                    </Col>
                    
                    <Col xs={12} className={'text-center padding-small'}>
                        <BackButton label={'Back'} onClick={()=> switchRoute(PATH_URLS.LOGIN)}/>
                        {loading
                         ? <SpinnerButton label={'Sending '}/>
                         : <SubmitButton label={'Send email '} icon={<ArrowRightCircleFill size={12}/>}/>
                        }
                    </Col>
                </Row>
            </Form>
        </Formik>
    
    )
};