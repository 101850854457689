import React, {Fragment} from 'react'
import AvatarEditor from 'react-avatar-editor';
import {TrashFill} from 'react-bootstrap-icons';
import {RemoveButton} from "components/Buttons";

// function formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) return '0 Bytes';
//
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//
//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
// }

class ImageUploader extends React.Component {
    constructor(props) {
        super(props);

        this.defaultAvatar = props.image
            ? props.image
            : props.gender === 1
                ? 'man_volleyball_player.png'
                : props.gender === 2 && 'woman_volleyball_player.png';
        this.width = props.width ? props.width : 170;
        this.height = props.height ? props.height : 300;
        this.allowZoomOut = props.allowZoomOut ? props.allowZoomOut : false;

        this.state = {
            image: this.defaultAvatar,
            allowZoomOut: this.allowZoomOut,
            position: {x: 0.5, y: 0.5},
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: this.width,
            height: this.height,
        }
    }

    handleNewImage = e => {
        if(e.target.files[0].size > 5000000){
            this.props.imageIsTooBig(true);
        }
        this.setState({
            image: e.target.files[0],
        });
        
    };

    handleScale = e => {
        const scale = parseFloat(e.target.value);
        this.setState({scale})
    };

    handlePositionChange = position => {
        this.setState({position})
    };

    handleRemoveImage = () => {
        this.setState({
            image: null,
        });
        this.props.imageIsTooBig(false);
        this.defaultAvatar = this.props.gender === 2 ? 'woman_volleyball_player.png' : 'man_volleyball_player.png';
    };
    render() {
        const {editorRef, gender} = this.props;
        const {image, scale, position, width, height, rotate, borderRadius} = this.state;
        return (
            <Fragment>
                {image
                    ? <Fragment>
                        <div className={'display-flex justify-center'}>
                            <AvatarEditor
                                ref={editorRef}
                                scale={parseFloat(scale)}
                                width={width}
                                height={height}
                                position={position}
                                onPositionChange={this.handlePositionChange}
                                rotate={parseFloat(rotate)}
                                borderRadius={0}
                                image={image}
                                className="editor-canvas"
                            />
                        </div>
                        <div className={'display-flex align-center  justify-center'}>
                            <div>
                                <input
                                    name="scale"
                                    type="range"
                                    onChange={this.handleScale}
                                    min={this.state.allowZoomOut ? '0.1' : '1'}
                                    max="2"
                                    step="0.01"
                                    defaultValue="1"
                                />
                            </div>
                            <div onClick={this.handleRemoveImage}>
                                <RemoveButton icon={<TrashFill/>}/>
                            </div>
                        </div>
                    </Fragment>
                    : <div className={'display-flex  justify-center'}>
                        <input name="newImage" type="file" onChange={this.handleNewImage}/>
                    </div>
                }
            </Fragment>
        )
    }
}

export default ImageUploader;