import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import EventSideBar from 'components/EventSidebar/EventSidebar';
import {List} from 'react-bootstrap-icons';
import {SCREEN_WIDTH_SIZE, PATH_URLS} from 'constants/index';
import {eventRoutes} from "config/routes";
import {Switch, Route} from "react-router";
import {connect} from 'react-redux';
import * as AppActions from 'actions';

class EventDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {},
            openSideBar: window.screen.availWidth > SCREEN_WIDTH_SIZE.SM
        };
    }
    
    handleOpenSidebar = () => {
        this.setState({
            openSideBar: true
        });
    };
    
    handleCloseSidebar = () => {
        this.setState({
            openSideBar: false
        });
    };
    
    handleSelectMenuItemFromSmallDevices = () => {
       if(window.screen.availWidth <= 900){
           this.handleCloseSidebar();
       }
       
    }
    
    
    render() {
        const {event, openSideBar} = this.state;
        return (
            <Fragment>
                {event &&
                 <div className={openSideBar
                                 ? 'event-details-container-openedSidebar'
                                 : 'event-details-container-closedSidebar'}
                 >
                     <EventSideBar
                         closeSidebar={this.handleCloseSidebar}
                         openSideBar={openSideBar}
                         eventId={this.props.match.params.id}
                         isLoggedIn={this.props.isLoggedIn}
                         onSelectMenuItem={this.handleSelectMenuItemFromSmallDevices}
                     />
                     <div
                         className={
                             openSideBar
                             ? 'open-sidebar-container-btn open-sidebar-btn-container-hidden'
                             : 'open-sidebar-container-btn open-sidebar-btn-container-visible'
                         }
                     >
                         <Button className={'open-sidebar-btn'} onClick={this.handleOpenSidebar}>
                             <List size={23}/>
                         </Button>
                     </div>
                     <div style={{padding: '12px'}}>
                         <Switch>
                             {eventRoutes.map((route, index) => {
                                 if (route.isAvailable(this.props.isLoggedIn)) {
                                     return (
                                         <Route
                                             key={index}
                                             path={`${PATH_URLS.EVENT}/${this.props.match.params.id}${route.path}`}
                                             exact={route.exact}
                                             component={route.component}
                                         />
                                     );
                                 }
                             })}
                         </Switch>
                     </div>
                 </div>
                }
            </Fragment>
        
        );
    }
    
    async componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getTournamentById(id);
    }
    
    componentWillUnmount() {
        this.props.resetTournamentById();
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.appReducer.isLoggedIn
});
const mapDispatchToProps = (dispatch) => ({
    getTournamentById: (item) => dispatch(AppActions.getTournamentById(item)),
    resetTournamentById: () => dispatch(AppActions.resetTournamentById())
    
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EventDetailsPage);
export default withRouter(withConnect);