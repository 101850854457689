import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {PageTitle} from 'components/PageTitle';
import {EventInfoBanner} from 'components/EventInfoBanner';
import {TableComponent} from 'components/Table';
import {EVENT_CATEGORY} from "constants/index";
import {CircleFill} from 'react-bootstrap-icons';
import TeamsFilterForm from 'pages/eventDetails/teams/filterForm';
import {connect} from "react-redux";
import * as AppActions from "actions";
import {getTeamStatusName} from 'utils';

class EventPlayers extends React.Component {

    onFilterTeams = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        let params = {tournamentId: this.props.event.id, categoryId: category, status: values.status};
        this.props.getTeams(params);
    };

    render() {
        const {teamsList, loading, event} = this.props;


        const header = [
            {
                display: 'Category',
                property: 'category',
                map: ((item, index) => ({
                        display: <div key={index}>
                            <CircleFill color={item.category === EVENT_CATEGORY.MALE ? '#002b7f' : '#fcd116'} />
                        </div>
                    })
                )
            },
            {
                display: 'Team Name',
                property: 'teamName',
                emphasizedColumn: true
            },

            {
                display: 'Player 1',
                property: 'players',
                map: ((item, index) => ({
                        display: item.players[0]?.fullName
                    })
                )
            },
            {
                display: 'Player 2',
                property: 'players',
                map: ((item, index) => ({
                        display: item.players[1]?.fullName
                    })
                )
            },

            {
                display: 'Cities',
                property: 'players',
                map: ((item, index) => ({
                        display: <div key={index}>
                            {item.players[0]?.cityName} / {item.players[1]?.cityName}
                        </div>
                    })
                )
            },
            {
                display: 'Status',
                property: 'status',
                map: ((item, index) => {
                        let teamStatus = getTeamStatusName(item.status);
                        return ({
                            display: <div key={index} className={`team-status${item.status}`}>
                                {teamStatus}
                            </div>
                        })
                    }
                )
            },
            {display: 'Points', property: 'points'}
        ];
        return (
            <Row>
                {event &&
                <Fragment>
                    <Col xs={12}>
                        <EventInfoBanner event={event} />
                        <PageTitle title={'Teams'} />
                    </Col>
                    <div className={'filter-form-container'}>
                        <TeamsFilterForm onFilterTeams={this.onFilterTeams} />
                    </div>
                    <Col xs={12}>
                        <TableComponent
                            data={teamsList}
                            header={header}
                            loading={loading}
                            noDataMessage={'There are no teams registered yet'}
                        />
                    </Col>
                </Fragment>
                }
            </Row>
        );
    }

    componentDidMount() {
        const eventId = this.props.match.path.split("/")[2];
        let params = {tournamentId: eventId};
        this.props.getTeams(params);

    }

    componentWillUnmount() {
        this.props.resetTeams();
    }
}

const mapStateToProps = (state) => ({
    teamsList: state.appReducer.teamsList,
    loading: state.appReducer.loading,
    event: state.appReducer.tournamentDetails
});

const mapDispatchToProps = (dispatch) => ({
    getTeams: (item) => dispatch(AppActions.getTeams(item)),
    resetTeams: () => dispatch(AppActions.resetTeams())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EventPlayers);
export default withConnect;