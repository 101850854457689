import React, {Component} from 'react'
import {AddButton} from "components/Buttons";
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS} from 'constants/index';
import {connect} from 'react-redux';
import * as AppActions from 'actions';

class Admin_PlayersHistory extends Component{
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
    };
    
    onOpenForm = (item) => {
        this.setState({
            openForm: true,
            editItem: item
        })
    };
    
    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        })
    };
    render(){
        const {playerHistory} = this.props;
        return(
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_PLAYERS}`, name: 'Players'},
                    {path: PATH_URLS.ADMIN_PLAYERS_HISTORY, name: 'Players History'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Players History</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                </div>
            </div>
        )
    }
    
    async componentDidMount() {
        const playerId = this.props.match.params.id;
        if (playerId) {
            this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    playerId: playerId
                }
            }));
            this.props.getPlayerHistory({playerId: playerId});
        }
        
    }
}

const mapStateToProps = (state) => ({
    playerHistory: state.appReducer.playerHistory,
    // playersList: state.appReducer.playersList,
    // loading: state.appReducer.loading,
    // tournamentsList: state.appReducer.managerTournamentsList,
    // saveLoading: state.appReducer.saveLoading
});

const mapDispatchToProps = (dispatch) => ({
    getPlayerHistory: (item) => dispatch(AppActions.getPlayerHistory(item)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_PlayersHistory);
export default withConnect;