import React from 'react';
import {connect} from "react-redux";
import {Col, Row} from 'react-bootstrap';
import * as AppActions from "actions";
import {ModalComponent} from "components/Modal";
import {ForgotPasswordForm} from 'components/ForgotPasswordForm'
import {PATH_URLS} from 'constants/index';

const ForgotPasswordPage = (
    {
        loading,
        forgotPassword,
        switchRoute
    }) => {
    
    let onResetPassword = (email) =>{
        forgotPassword(email)
    };
    return (
        <Col className={'page-container'}>
            <Row>
                <ModalComponent
                    show={true}
                    onHide={()=>switchRoute(PATH_URLS.LOGIN)}
                    title={'Did you forget your password?'}
                >
                    <div className={'padding-small'}>Please tell us which is you e-mail address. We will send you an e-mail with your new password.</div>
                    <ForgotPasswordForm onSendEmail={onResetPassword} loading={loading} switchRoute={switchRoute}/>
                </ModalComponent>
            </Row>
        </Col>
    )
};

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (credentials) => dispatch(AppActions.forgotPassword(credentials)),
    switchRoute: (path) => dispatch(AppActions.switchRoute(path)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
export default withConnect;