import React from 'react';
import {Container, Col, Row} from 'react-bootstrap'
import {PageTitle} from 'components/PageTitle'
import {EventInfoBanner} from 'components/EventInfoBanner'
import {At, Phone, Facebook} from 'react-bootstrap-icons'
import {connect} from 'react-redux';
import {getFormattedURL} from 'utils';

const EventContact = ({event}) => {
    return (
        <Row>
            <Col xs={12} className={'page-container'}>
                <EventInfoBanner event={event}/>
                <PageTitle title={'Contact'}/>
                <Row>

                    <Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}
                         className={'contact-container container-with-flag'}>
                        <Container>
                            <Row>
                                <Col xs={12} md={5} className={'contact-label'}><At/> E-mail Address: </Col>
                                <Col xs={12} md={7} className={'contact-value'}>{event.contactEmail}</Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={5} className={'contact-label'}><Phone/> Phone Number: </Col>
                                <Col xs={12} md={7} className={'contact-value'}>{event.contactPhone}</Col>
                            </Row>
                            {event.facebookEventLink &&
                            <Row>
                                <Col xs={12} md={5} className={'contact-label'}>Facebook Event: </Col>
                                <a href={getFormattedURL(event.facebookEventLink)} target={'_blank'}>
                                    <Col xs={12} md={7}  className={'facebook-link'}>
                                        <Facebook size={20}/>
                                    </Col>
                                </a>
                            </Row>
                            }
                        </Container>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
};

const mapStateToProps = (state) => ({
    event: state.appReducer.tournamentDetails,
});

const withConnect = connect(mapStateToProps)(EventContact);
export default withConnect;