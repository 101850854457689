import React from 'react';
import {Switch, Route} from "react-router";
import {routes} from 'config/routes';

export const RouterComponent = ({isLoggedIn}) => {
    return (
        <Switch>
            {routes.map((route, index)=>{
                if (route.isAvailable(isLoggedIn)) {
                    return(
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    )
                }
            })}
        </Switch>
    )
};
