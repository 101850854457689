import React from 'react';
import {Formik, Field, Form} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {CustomDropdownComponent, CustomRadioCheckComponent} from 'components/RenderInput';

const emptyFormObject = {
    tournamentId: '',
    category: '',
};

const RankingsFilterForm = ({tournamentsList, onSubmit, initialValues}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <Row >
                    <Col xs={6} sm={2} className={'input-container'}>
                        <Field
                            name="tournamentId"
                            component={CustomDropdownComponent}
                            options={tournamentsList}
                            label={'Tournament'}
                            submitOnChange
                            disabled={tournamentsList && tournamentsList.length === 1}
                            noEmptyField
                        />
                    </Col>
                    <Col xs={7} sm={4} className={'display-flex checkbox-container'}>
                        <Field
                            name="category" type={'radio'} value={1}
                            component={CustomRadioCheckComponent}
                            label={'Men'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={2}
                            component={CustomRadioCheckComponent}
                            label={'Women'} submitOnChange/>
                    </Col>
                </Row>
            </Form>
        </Formik>
    
    );
};

export default RankingsFilterForm;
