import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {PageTitle} from 'components/PageTitle';
import {EventInfoBanner} from 'components/EventInfoBanner';
import {TableComponent} from 'components/Table';
import {EVENT_CATEGORY, TIME_FORMATS, LOCAL_STORAGE_TAGS} from 'constants/index';
import moment from 'moment';
import {CircleFill} from 'react-bootstrap-icons';
import {connect} from "react-redux";
import * as AppActions from "actions";
import GamesFilterForm from 'pages/eventDetails/games/filterForm';
import {getTeamsOptions} from 'utils/index';

class EventGames extends React.Component {
    
    onFilterGames = async (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        
        let params = {
            tournamentId: this.props.event.id,
            categoryId: category,
            teamId: +values.teamId || null,
            scheduleId: values.scheduleId
        };
        await this.props.getTeams({
            tournamentId: this.props.event.id,
            categoryId: category
        });
        
        this.props.getGames(params);
    };
    
    render() {
        const {gamesList, loading, teamsList, event, schedulesList} = this.props;
        const header = [
            {
                display: '',
                property: 'category',
                map: ((item, index) => ({
                        display: <div key={index}>
                            <CircleFill color={item.category === EVENT_CATEGORY.MALE ? '#002b7f' : '#fcd116'}/>
                        </div>
                    })
                )
            },
            {
                display: 'Team 1',
                map: ((item, index) => ({
                        display: <div key={index}>
                            <div className={'font-bold'}>
                                {item.teamOneName}
                            </div>
                            <div className={'team-players-names'}>
                                {item.playerOneTeamOne && `${item.playerOneTeamOne} & ${item.playerTwoTeamOne}`}
                            </div>
                        </div>
                    })
                ),
            },
            {
                display: 'Team 2',
                map: ((item, index) => ({
                        display: <div key={index}>
                            <div className={'font-bold'}>
                                {item.teamTwoName}
                            </div>
                            <div className={'team-players-names'}>
                                {item.playerOneTeamTwo && `${item.playerOneTeamTwo} & ${item.playerTwoTeamTwo}`}
                            </div>
                        </div>
                    })
                ),
            },
            
            {
                display: 'Time',
                map: ((item, index) => ({
                        display: <Fragment key={index}>
                            <div className={'font-bold'}>{moment(item.startDate).format('HH:mm')}</div>
                            <div className={'font-small'}>{moment(item.startDate).format('DD-MM-YYYY')}</div>
                        </Fragment>
                    })
                )
            },
            
            {display: 'Game No.', property: 'gameNumber'},
            {
                display: 'Score',
                property: 'finalScore',
                map: ((item, index) => ({
                        display: <Fragment key={index}>
                            <div className={'font-bold'}>{item.finalScore || '--:--'}</div>
                            <div className={'font-small'}>
                                {item.gameResultsDto && item.gameResultsDto.setResults.map((set, index) => (
                                    <span key={index}>
                                {set.teamOneScore}
                                        {' - '}
                                        {set.teamTwoScore}
                                        {item.gameResultsDto.setResults.length > index + 1 ? ' | ' : null}
                                </span>
                                ))
                                }
                            </div>
                        </Fragment>
                    })
                )
            }
        ];
        return (
            <Row>
                {event &&
                 <Fragment>
                     <Col xs={12}>
                         <EventInfoBanner event={event}/>
                         <PageTitle title={'Games'}/>
                     </Col>
                     <Col xs={12}>
                         <GamesFilterForm
                             teamsOptions={teamsList}
                             scheduleOptions={schedulesList}
                             onFilterGames={this.onFilterGames}
                         />
                     </Col>
                     <Col xs={12}>
                         <TableComponent
                             data={gamesList}
                             header={header}
                             loading={loading}
                         />
                     </Col>
                 </Fragment>
                }
            </Row>
        );
    }
    
    componentDidMount() {
        const {event} = this.props;
        const eventId = this.props.match.path.split("/")[2];
        let params = {tournamentId: eventId};
        this.props.getGames({
            ...params,
            startDate: moment(event.startDate).format(TIME_FORMATS.API_REQUEST),
            endDate: moment(event.endDate).format(TIME_FORMATS.API_REQUEST)
        });
        this.props.getTeams(params);
        this.props.getSchedules(params);
    }
    
    componentWillUnmount() {
        this.props.resetGames();
        this.props.resetTeams();
        this.props.resetSchedules();
    }
}

const mapStateToProps = (state) => ({
    gamesList: state.appReducer.gamesList,
    teamsList: getTeamsOptions(state.appReducer.teamsList),
    schedulesList: state.appReducer.schedulesList,
    loading: state.appReducer.loading,
    event: state.appReducer.tournamentDetails
});

const mapDispatchToProps = (dispatch) => ({
    getGames: (item) => dispatch(AppActions.getGames(item)),
    resetGames: () => dispatch(AppActions.resetGames()),
    getTeams: (item) => dispatch(AppActions.getTeams(item)),
    resetTeams: () => dispatch(AppActions.resetTeams()),
    getSchedules: (item) => dispatch(AppActions.getSchedules(item)),
    resetSchedules: (item) => dispatch(AppActions.resetSchedules(item)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EventGames);
export default withConnect;