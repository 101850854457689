import React from 'react';
import {Col, Row, Container} from 'react-bootstrap'
import {At, Phone} from 'react-bootstrap-icons'
import {PageTitle} from "components/PageTitle";

const ContactPage = () => {
    return (
        <Col className={'page-container'}>
            <Row>
                <PageTitle title={"Contact Information"}/>
                <Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}
                     className={'contact-container container-with-flag'}>
                    <Container>
                        <Row>
                            <Col xs={5} className={'contact-label'}><At/> E-mail Address: </Col>
                            <Col xs={7} className={'contact-value'}>vopetru@gmail.com</Col>
                        </Row>
                        <Row>
                            <Col xs={5} className={'contact-label'}><Phone/> Phone Number: </Col>
                            <Col xs={7} className={'contact-value'}>(+40)753 457 974</Col>
                        </Row>
                        <Row>
                            <Col xs={5} className={'contact-label'}>Facebook: </Col>
                            <a href={'https://www.facebook.com/vo.petru'}>
                                <Col xs={7}
                                     className={'contact-value'}>facebook.com/vo.petru</Col></a>
                        </Row>
                    </Container>
                </Col>
            </Row>


        </Col>
    )
};

export default ContactPage;