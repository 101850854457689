import React, {Fragment, Component} from 'react';
import {AddButton} from "components/Buttons";
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {TableComponent} from "components/Table";
import {ModalComponent, ConfirmModal} from "components/Modal";
import {PATH_URLS, REGISTERED_TEAM_STATUS} from 'constants/index';
import {connect} from 'react-redux';
import * as AppActions from 'actions';
import RankingsForm from 'pages/admin_pages/rankings/form';
import RankingsFilterForm from 'pages/admin_pages/rankings/filterForm';
import {getTeamsOptions} from 'utils';

class Admin_Rankings extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
        filter: {
            category: 1
        }
    };
    getFilterParams = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        return {categoryId: category, tournamentId: values.tournamentId};
    };

    filterData = (values) => {
        let params = this.getFilterParams(values);
        this.props.getTournamentRankings(params);
        this.setState({
            filter: values
        })
    };
    onOpenForm = (item) => {
        let params = this.getFilterParams(this.state.filter);
        this.props.getTeams({
            ...params,
            status: REGISTERED_TEAM_STATUS.CONFIRMED
        });
        this.setState({
            openForm: true,
            editItem: item
        });
    };

    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        });
    };
    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        });
    };

    onConfirmDelete = async () => {
        await this.props.deleteTournamentRankings(this.state.deleteItem);
        this.closeConfirm();
        let params = this.getFilterParams(this.state.filter);
        this.props.getTournamentRankings(params);
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        });
    };

    saveRankings = async (values) => {
        if (this.state.editItem) {
            await this.props.editTournamentRankings(values);
        } else {
            await this.props.addTournamentRankings(values);
        }
        this.closeForm();
        let params = this.getFilterParams(this.state.filter);
        this.props.getTournamentRankings(params);
    };

    render() {
        const {loading, tournamentRankingsList, tournamentsList, saveLoading, teamsList, switchRoute} = this.props;
        const {editItem, openForm, openConfirm, filter, deleteItem} = this.state;

        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_TOURNAMENT_RANKINGS, name: 'Rankings'}
                ]} />
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Final Rankings</div>
                        <AddButton onClick={() => this.onOpenForm(null)} />
                    </div>
                    {tournamentsList && tournamentsList.length > 0 &&
                    <Fragment>
                        <div className={'filter-container'}>
                            <RankingsFilterForm
                                onSubmit={this.filterData}
                                initialValues={{
                                    ...filter,
                                    tournamentId: filter.tournamentId || tournamentsList[0].id
                                }}
                                tournamentsList={tournamentsList}
                            />
                        </div>

                        <TableComponent
                            data={tournamentRankingsList}
                            header={header}
                            editable={true}
                            removable={true}
                            onEditItem={this.onOpenForm}
                            onDeleteItem={this.onDeleteItem}
                            loading={loading}
                        />

                        <ModalComponent
                            show={openForm}
                            onHide={this.closeForm}
                            title={editItem ? 'Update Ranking' : 'Add New Ranking'}
                        >
                            {teamsList && teamsList.length > 0 &&
                            <RankingsForm
                                initialValues={editItem
                                    ? editItem
                                    : {
                                        ...filter,
                                        tournamentId: filter.tournamentId || tournamentsList[0].id
                                    }}
                                teamsList={teamsList}
                                onSubmit={this.saveRankings}
                                saveLoading={saveLoading}
                            />
                            }
                        </ModalComponent>

                        <ConfirmModal
                            show={openConfirm}
                            onHide={this.closeConfirm}
                            title={`Remove Ranking`}
                            handleConfirm={this.onConfirmDelete}
                            handleClose={this.closeConfirm}
                            content={`Are you sure you want to remove ${deleteItem.teamName} team's ranking?`}
                        />
                    </Fragment>
                    }
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.resetTeams();
        this.props.resetTournaments();
    }

    async componentDidMount() {
        const tournaments = await this.props.getTournaments();
        if (tournaments && tournaments.length > 0) {
            await this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    tournamentId: tournaments[0].id
                }
            }));
            let params = this.getFilterParams(this.state.filter);
            this.props.getTournamentRankings(params);
        }
    }
}

const mapStateToProps = (state) => ({
    tournamentsList: state.appReducer.managerTournamentsList,
    saveLoading: state.appReducer.saveLoading,
    teamsList: getTeamsOptions(state.appReducer.teamsList),
    tournamentRankingsList: state.appReducer.tournamentRankingsList,
});

const mapDispatchToProps = (dispatch) => ({
    switchRoute: (path) => dispatch(AppActions.switchRoute(path)),
    getTeams: (params) => dispatch(AppActions.getTeams(params)),
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
    getTournamentRankings: (item) => dispatch(AppActions.getTournamentRankings(item)),
    resetTournamentRankings: () => dispatch(AppActions.resetTournamentRankings()),
    addTournamentRankings: (values) => dispatch(AppActions.addTournamentRankings(values)),
    editTournamentRankings: (values) => dispatch(AppActions.editTournamentRankings(values)),
    deleteTournamentRankings: (values) => dispatch(AppActions.deleteTournamentRankings(values)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Rankings);
export default withConnect;

const header = [
    {
        display: 'Place',
        property: 'place'
    },
    {
        display: 'Team Name',
        property: 'teamName',
        emphasizedColumn: true
    },
    {
        display: 'Player 1',
        property: 'player1',
        emphasizedColumn: true,
        map: (item, index) => ({display: <div key={index}>{item.players[0]}</div>})
    },
    {
        display: 'Player 2',
        property: 'player2',
        emphasizedColumn: true,
        map: (item, index) => ({display: <div key={index}>{item.players[1]}</div>})
    },

    {
        display: 'Points',
        property: 'points'
    }
];