import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {PageTitle} from 'components/PageTitle';
import {EventInfoBanner} from 'components/EventInfoBanner';
import * as AppActions from 'actions';
import {connect} from 'react-redux';
import {DOC_TYPES} from 'constants/index';
import {JournalText} from 'react-bootstrap-icons';
import {Loader} from 'components/Loader';
import {base64ToArrayBuffer} from 'actions';

class EventDocuments extends React.Component {
    
    handleDownloadFile = (doc) => {
        base64ToArrayBuffer({
            fileContents: doc.file,
            contentType: doc.fileType || 'application/pdf',
            fileDownloadName: doc.name
        })
    }
    
    render() {
        const {event, documents, loading} = this.props;
        return (
            <Row>
                <Col xs={12}>
                    <EventInfoBanner event={event}/>
                    <PageTitle title={'Files'}/>
                </Col>
                {loading
                 ? <Loader/>
                 : documents && documents.length > 0
                   ? <>
                       {documents.map(doc => (
                           <Col xs={12} sm={6} lg={4} onClick={() => this.handleDownloadFile(doc)}>
                               <div className={'choose-doc-button'}>
                                   <div><JournalText size={40}/></div>
                                   <div>
                                       <div>Download</div>
                                       <div className={'font-bold'}>
                                           {doc.name}
                                       </div>
                                   </div>
                               </div>
                           </Col>
                       ))}
                   </>
                   : <div className={'no-data-message'}>There are no files uploaded for this event</div>
                }
            </Row>
        );
    }
    
    componentDidMount() {
        const eventId = this.props.match.path.split("/")[2];
        let params = {tournamentId: eventId};
        this.props.getTournamentDocuments(params);
    }
}

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading,
    event: state.appReducer.tournamentDetails,
    documents: state.appReducer.documents
});

const mapDispatchToProps = (dispatch) => ({
    getTournamentDocuments: (item) => dispatch(AppActions.getTournamentDocuments(item))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EventDocuments);
export default withConnect;