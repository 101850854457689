import React, {Component, Fragment} from 'react';
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS, EVENT_CATEGORY} from "constants/index";
import {AddButton} from "components/Buttons";
import {TableComponent} from "components/Table";
import {ModalComponent, ConfirmModal} from "components/Modal";
import {connect} from "react-redux";
import * as AppActions from "actions";
import TeamsForm from 'pages/admin_pages/teams/form';
import TeamsFilterForm from 'pages/admin_pages/teams/filterForm';
import {CircleFill} from 'react-bootstrap-icons';
import {Badge} from 'react-bootstrap';
import {getTeamStatusName} from 'utils';

class Admin_Teams extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
        filter: {
            category: 0
        }
    };
    
    getFilterParams = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        return {tournamentId: values.tournamentId, categoryId: category, status: values.status};
    };
    
    filterData = (values) => {
        let params = this.getFilterParams(values);
        this.props.getTeams(params);
        this.setState({
            filter: values
        });
    };
    
    onOpenForm = async (item) => {
        if (item) {
            let params = this.getFilterParams(item);
            await this.props.getPlayers({
                ...params,
                categoryId: item.category,
                onlyUnregistered: true,
                teamId: item.teamId
            });
            
        }
        this.setState({
            openForm: true,
            editItem: item
        });
    };
    
    handleChangeTeamCategory = (value, formProps) => {
        // TODO: set field value to null if the values is not existent in the new list
        let params = this.getFilterParams(this.state.filter);
        this.props.getPlayers({
            ...params,
            categoryId: value,
            onlyUnregistered: true
        });
    };
    
    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        });
    };
    
    saveTeam = async (values) => {
        if (this.state.editItem) {
            await this.props.editTeam(values);
        } else {
            await this.props.addTeam(values);
        }
        
        let params = this.getFilterParams(this.state.filter);
        this.props.getTeams(params);
        this.closeForm();
    };
    
    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        });
    };
    
    onConfirmDelete = async () => {
        await this.props.deleteTeam(this.state.deleteItem);
        let params = this.getFilterParams(this.state.filter);
        this.props.getTeams(params);
        this.closeConfirm();
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        });
    };
    render() {
        const {saveLoading, loading, teamsList, tournamentsList, playersList, sizesOptions} = this.props;
        const {openForm, editItem, openConfirm, deleteItem, filter} = this.state;

        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_TEAMS, name: 'Teams'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Teams</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                    {tournamentsList && tournamentsList.length > 0 &&
                     <Fragment>
                         <div className={'filter-container'}>
                             <TeamsFilterForm
                                 tournamentsList={tournamentsList}
                                 onSubmit={this.filterData}
                                 initialValues={{
                                     ...filter,
                                     tournamentId: filter.tournamentId || tournamentsList[0].id
                                 }}
                             />
                         </div>
                         <TableComponent
                             data={teamsList}
                             header={header(sizesOptions)}
                             editable={true}
                             removable={true}
                             onEditItem={this.onOpenForm}
                             onDeleteItem={this.onDeleteItem}
                             loading={loading}
                         />
                        
                         <ModalComponent
                             show={openForm}
                             onHide={this.closeForm}
                             title={editItem ? 'Update Team' : 'Add New Team'}
                         >
                             <TeamsForm
                                 initialValues={editItem
                                                ? editItem
                                                : {
                                         ...filter,
                                         tournamentId: filter.tournamentId || tournamentsList[0].id
                                     }}
                                 edit={!!editItem}
                                 onSubmit={this.saveTeam}
                                 tournamentsList={tournamentsList}
                                 saveLoading={saveLoading}
                                 playersList={playersList}
                                 onChangeTeamCategory={this.handleChangeTeamCategory}
                                 sizesOptions={sizesOptions}
                             />
                         </ModalComponent>
                        
                         <ConfirmModal
                             show={openConfirm}
                             onHide={this.closeConfirm}
                             title={`Remove Team`}
                             handleConfirm={this.onConfirmDelete}
                             handleClose={this.closeConfirm}
                             content={<div>Are you sure you want to remove the {deleteItem.teamName} team?</div>}
                         />
                     </Fragment>
                    }
                
                </div>
            </div>
        );
    }
    
    componentWillUnmount() {
        this.props.resetTeams();
        this.props.resetPlayers();
        this.props.resetTournaments();
        this.props.resetSizes();
    }
    
    async componentDidMount() {
        const tournaments = await this.props.getTournaments();
        if (tournaments && tournaments.length > 0) {
            await this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    tournamentId: tournaments[0].id
                }
            }));
            let params = this.getFilterParams(this.state.filter);
            this.props.getTeams(params);
            this.props.getSizes();
        }
        
    }
    
}

const mapStateToProps = (state) => ({
    teamsList: state.appReducer.teamsList,
    playersList: state.appReducer.playersList,
    loading: state.appReducer.loading,
    tournamentsList: state.appReducer.managerTournamentsList,
    saveLoading: state.appReducer.saveLoading,
    sizesOptions: state.appReducer.sizesList
});

const mapDispatchToProps = (dispatch) => ({
    getTeams: (item) => dispatch(AppActions.getTeams(item)),
    resetTeams: () => dispatch(AppActions.resetTeams()),
    resetSizes: () => dispatch(AppActions.resetSizes()),
    addTeam: (item) => dispatch(AppActions.addTeam(item)),
    editTeam: (item) => dispatch(AppActions.editTeam(item)),
    deleteTeam: (item) => dispatch(AppActions.deleteTeam(item)),
    getSizes: () => dispatch(AppActions.getSizes()),
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
    getPlayers: (item) => dispatch(AppActions.getPlayers(item)),
    resetPlayers: () => dispatch(AppActions.resetPlayers())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Teams);
export default withConnect;

const header = (sizesOptions) => [
    {
        display: 'Team Name',
        property: 'teamName',
        map: ((item, index) => ({
                display: <div key={index}>
                    <CircleFill color={item.category === EVENT_CATEGORY.MALE ? '#002b7f' : '#fcd116'}/>&nbsp;
                    {item.teamName}
                </div>
            })
        )
    },
    {
        display: 'Player 1',
        property: 'players',
        map: ((item, index) => ({
                display: item.players[0] && <div>{item.players[0].fullName}
                    <Badge
                        style={{float: 'right'}}
                        bg="secondary"
                    >
                        {item.players[0]?.sizeName}
                    </Badge>
                </div>
            })
        )
    },
    {
        display: 'Player 2',
        property: 'players',
        map: ((item, index) => ({
                display: item.players[1] && <div>{item.players[1].fullName}
                    <Badge
                        style={{float: 'right'}}
                        bg="secondary"
                    >
                        {item.players[1]?.sizeName}
                    </Badge>
                </div>
            })
        )
    },
    
    {
        display: 'Cities',
        property: 'players',
        map: ((item, index) => ({
                display: <div key={index}>
                    {item.players[0] && item.players[0].cityName} / {item.players[1] && item.players[1].cityName}
                </div>
            })
        )
    },
    {display: 'E-mail', property: 'email'},
    {
        display: 'Status',
        property: 'status',
        map: ((item, index) => {
                let teamStatus = getTeamStatusName(item.status);
                return ({
                    display: <div key={index} className={`team-status${item.status}`}>
                        {teamStatus}
                    </div>
                });
            }
        )
    },
    {display: 'Points', property: 'points'}
];
