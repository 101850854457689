import React, {Fragment} from 'react';
import {PATH_URLS} from 'constants/index';
import {BreadcrumbComponent} from 'components/Breadcrumb';
import * as AppActions from 'actions';
import {connect} from 'react-redux';
import ReportsFilterForm from './filterForm';
import {DownloadButton, SubmitButton} from 'components/Buttons';
import {Spinner} from 'react-bootstrap';

class AdminReports extends React.Component {
    state = {
        filter: {}
    };
    
    filterData = (values) => {
        this.setState({
            filter: values
        });
    };
    
    render() {
        const {tournamentsList, downloadLoading} = this.props;
        const {filter} = this.state;
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_REPORTS, name: 'Reports'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Reports</div>
                    </div>
                    {tournamentsList && tournamentsList.length > 0 &&
                     <Fragment>
                         <div className={'filter-container'}>
                             <ReportsFilterForm
                                 tournamentsList={tournamentsList}
                                 initialValues={{
                                     ...filter,
                                     tournamentId: filter.tournamentId || tournamentsList[0].id
                                 }}
                                 onSubmit={this.filterData}
                             />
                         </div>
                         <div>Download the .xls files with the following information:</div>
                         <div className={'display-flex align-center'}>
                             <DownloadButton
                                   label={'Teams Details'}
                                   onClick={() => this.props.downloadTeamDetailsReport({tournamentId: filter.tournamentId})}
                                   type={'button'}
                                   disabled={downloadLoading}
                               />
                             <DownloadButton
                                 label={'Games Timetable'}
                                 onClick={() => this.props.downloadGamesTimetableReport({tournamentId: filter.tournamentId})}
                                 type={'button'}
                                 disabled={downloadLoading}
                             />
                             <DownloadButton
                                 label={'Teams Final Ranking'}
                                 onClick={() => this.props.downloadFinalRankingReport({tournamentId: filter.tournamentId})}
                                 type={'button'}
                                 disabled={downloadLoading}
                             />
                             {downloadLoading && <Spinner
                                 as="div"
                                 animation="border"
                                 size="sm"
                                 role="status"
                                 aria-hidden="true"
                             />
                             }
                         </div>
                        
                         
                     </Fragment>
                    }
                </div>
            </div>
        );
    }
    
    async componentDidMount() {
        const tournaments = await this.props.getTournaments();
        if (tournaments && tournaments.length > 0) {
            await this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    tournamentId: tournaments[0].id
                }
            }));
        }
    }
    
    componentWillUnmount() {
        this.props.resetTournaments();
    }
}

const mapStateToProps = (state) => ({
    tournamentsList: state.appReducer.managerTournamentsList,
    downloadLoading: state.appReducer.downloadLoading,
});

const mapDispatchToProps = (dispatch) => ({
    switchRoute: (path) => dispatch(AppActions.switchRoute(path)),
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
    downloadTeamDetailsReport: (params) => dispatch(AppActions.downloadTeamDetailsReport(params)),
    downloadGamesTimetableReport: (params) => dispatch(AppActions.downloadGamesTimetableReport(params)),
    downloadFinalRankingReport: (params) => dispatch(AppActions.downloadFinalRankingReport(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(AdminReports);
export default withConnect;