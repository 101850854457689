import React from 'react';
import {Formik, Field, Form} from 'formik';
import {CustomDropdownComponent, CustomRadioCheckComponent} from 'components/RenderInput';
import {getTeamsStatusOptions} from 'utils';

const emptyFormObject = {
    tournamentId: '',
    category: '',
};

const TeamFilterForm = ({onSubmit, initialValues, tournamentsList}) => {
    const statusOptions = getTeamsStatusOptions();
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
        >
            <Form>
                <div className={'display-flex '}>
                    <div style={{paddingRight: '10px'}}>
                        <Field
                            name="tournamentId"
                            component={CustomDropdownComponent}
                            options={tournamentsList}
                            label={'Tournament'}
                            submitOnChange
                            disabled={tournamentsList && tournamentsList.length === 1}
                            noEmptyField
                        />
                    </div>
                    <div style={{paddingRight: '10px'}}>
                    <Field
                        name="status"
                        component={CustomDropdownComponent}
                        options={statusOptions}
                        label={'Status'}
                        submitOnChange
                    />
                    </div>
                    <div className={'display-flex checkbox-container'}>
                        <Field
                            name="category" type={'radio'} value={0}
                            component={CustomRadioCheckComponent}
                            label={'All'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={1}
                            component={CustomRadioCheckComponent}
                            label={'Men'} submitOnChange/>
                        <Field
                            name="category" type={'radio'} value={2}
                            component={CustomRadioCheckComponent}
                            label={'Women'} submitOnChange/>
                    </div>
        
                </div>
            </Form>
        </Formik>
    
    )
};

export default TeamFilterForm;
