import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form, withFormik} from 'formik';
import {CustomDropdownComponent, CustomInputComponent, CustomTextAreaComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";
import {GENDER_CATEGORY_OPTIONS} from 'constants/index';

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};
    
    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName = 'Required';
    }
    if (!values.gender) {
        errors.gender = 'Required';
    }
    
    return errors;
};

const emptyFormObject = {
    firstName: '',
    lastName: '',
    gender: '',
    cityId: '',
};

const PlayersForm = ({onSubmit, initialValues, saveLoading, citiesOptions}) => {
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={onSubmit}
            validate={validate}
            validateOnMount={true}
        >
            {formProps => (
                <Form>
                    <Row>
                        <Col xs={12} sm={6} className={'padding-small'}>
                            <Field name="lastName" component={CustomInputComponent} label={'Last Name'}/>
                        </Col>
                        <Col xs={12} sm={6} className={'padding-small'}>
                            <Field name="firstName" component={CustomInputComponent} label={'First Name'}/>
                        </Col>
                        <Col xs={12} sm={6} className={'padding-small'}>
                            <Field
                                name="gender"
                                component={CustomDropdownComponent}
                                options={GENDER_CATEGORY_OPTIONS}
                                label={'Gender'}
                            />
                        </Col>
                        <Col xs={12} sm={6} className={'padding-small'}>
                            <Field
                                name="cityId"
                                component={CustomDropdownComponent}
                                options={citiesOptions}
                                label={'City'}
                            />
                        </Col>
                    </Row>
                    <Row>
            
                        <Col xs={12} className={'text-right padding-small'}>
                            {saveLoading
                             ? <SpinnerButton label={'Saving'}/>
                             : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>} disabled={!formProps.isValid}/>
                            }
                        </Col>
                    </Row>
                </Form>
            )}
            
        </Formik>
    
    )
};

export default PlayersForm;
