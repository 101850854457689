import React, {Component, Fragment} from 'react';
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS} from "constants/index";
import {AddButton} from "components/Buttons";
import {TableComponent} from "components/Table";
import {Loader} from "components/Loader";
import {ModalComponent, ConfirmModal} from "components/Modal";
import {connect} from "react-redux";
import * as AppActions from "actions";
import {checkRole, getFormattedPeriod, getFormattedURL, getUsersOptions} from "utils/index";
import {Facebook} from 'react-bootstrap-icons';
import TournamentsForm from 'pages/admin_pages/tournaments/form';
import moment from 'moment';
import TournamentsFilterForm from './filterForm';

class Admin_Tournaments extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        editItem: null,
        deleteItem: {},
        filter:{
            year: moment().year()
        }
    };
    onOpenForm = async (item) => {
        if(item){
            const res = await this.props.getTournamentById(item.id);
    
            this.setState({
                openForm: true,
                editItem: res
            })
        } else{
            this.setState({
                openForm: true
            })
        }
    };

    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: null
        })
    };
    
    filterData = (values) =>{
        this.props.getTournaments(values);
        this.setState({filter: values});
    };

    saveTournament = async (values) => {
        if (this.state.editItem) {
            await this.props.editTournament({...values, cityID: +values.cityID});
        }
        else {
            await this.props.addTournament({...values, cityID: +values.cityID});
        }
        this.props.getTournaments(this.state.filter);
        this.closeForm();
    };

    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        })
    };

    onConfirmDelete = async () => {
        await this.props.deleteTournament(this.state.deleteItem);
        this.props.getTournaments(this.state.filter);
        this.closeConfirm();
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        })
    };
    render() {
        const {tournamentsList, citiesOptions, saveLoading, loading, usersOptions} = this.props;
        const {openForm, editItem, openConfirm, deleteItem, filter} = this.state;
        
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_TOURNAMENTS, name: 'Tournaments'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Tournaments</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
    
                    <div className={'filter-container'}>
                        <TournamentsFilterForm
                            onSubmit={this.filterData}
                            initialValues={filter}
                        />
                    </div>
                    
                    <TableComponent
                        data={tournamentsList}
                        header={header}
                        indexColumn
                        editable={true}
                        removable={true}
                        onEditItem={this.onOpenForm}
                        onDeleteItem={this.onDeleteItem}
                        loading={loading}
                    />
                    <ModalComponent
                        show={openForm}
                        onHide={this.closeForm}
                        title={editItem ? 'Update Tournament' : 'Add New Tournament'}
                    >
                        <TournamentsForm
                            initialValues={editItem &&
                            {
                                ...editItem,
                                startDate: moment(editItem.startDate).format('YYYY-MM-DD'),
                                endDate: moment(editItem.endDate).format('YYYY-MM-DD'),
                                startDateRegister: editItem.startDateRegister && moment(editItem.startDateRegister).format('YYYY-MM-DD'),
                                endDateRegister: editItem.endDateRegister && moment(editItem.endDateRegister).format('YYYY-MM-DD'),
                            }}
                            onSubmit={this.saveTournament}
                            citiesOptions={citiesOptions}
                            usersOptions={usersOptions}
                            saveLoading={saveLoading}
                        />
                    </ModalComponent>

                    <ConfirmModal
                        show={openConfirm}
                        onHide={this.closeConfirm}
                        title={`Remove Tournament`}
                        handleConfirm={this.onConfirmDelete}
                        handleClose={this.closeConfirm}
                        content={`Are you sure you want to remove ${deleteItem.name} tournament?`}
                    />
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        this.props.resetCities();
        this.props.resetTournamentById();
    }

    componentDidMount() {
        this.props.getTournaments(this.state.filter);
        this.props.getCities();
        if(checkRole('SuperAdmin')){
            this.props.getUsers();
        }
    }

}

const mapStateToProps = (state) => ({
    tournamentsList: state.appReducer.managerTournamentsList,
    loading: state.appReducer.managerTournamentsLoading,
    citiesOptions: state.appReducer.citiesList,
    usersOptions: getUsersOptions(state.appReducer.usersList),
    saveLoading: state.appReducer.saveLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getTournaments: (params) => dispatch(AppActions.getManagerTournaments(params)),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
    getTournamentById: (id) => dispatch(AppActions.getTournamentById(id)),
    resetTournamentById: () => dispatch(AppActions.resetTournamentById()),
    getUsers: () => dispatch(AppActions.getUsers()),
    getCities: () => dispatch(AppActions.getCities()),
    resetCities: () => dispatch(AppActions.resetCities()),
    addTournament: (item, image) => dispatch(AppActions.addTournament(item, image)),
    editTournament: (item, image) => dispatch(AppActions.editTournament(item, image)),
    deleteTournament: (item) => dispatch(AppActions.deleteTournament(item)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Tournaments);
export default withConnect;

const header = [
    {display: 'Tournament', property: 'name'},
    {display: 'City', property: 'cityName'},
    {
        display: 'Period', property: 'startDate',
        map: ((item, index) => ({
                display: <div key={index}>{getFormattedPeriod(item.startDate, item.endDate)}</div>
            })
        )
    },
    {
        display: 'Registration Details',
        property: 'startDateRegister',
        map: ((item, index) => ({
                display: <Fragment key={index}>
                    <div>{getFormattedPeriod(item.startDateRegister, item.endDateRegister)}</div>
                    <div>Teams allowed: M: {item.numberOfMenTeamsAllowed}; W:{item.numberOfWomenTeamsAllowed}</div>
                    <div>Teams registered: M: {item.numberOfMenTeamsRegistered}; W:{item.numberOfWomenTeamsRegistered}</div>
                </Fragment>
            })
        )
    },
    {
        display: 'Contact',
        map: ((item, index) => ({
                display: <Fragment key={index}>
                    <div>Phone: {item.contactPhone}</div>
                    <div>E-mail: {item.contactEmail}</div>
                </Fragment>
            })
        )
    },
    {
        display: 'Facebook Event',
        map: ((item, index) => {
                if (item.facebookEventLink) {
                    return ({
                        display: <a key={index} href={getFormattedURL(item.facebookEventLink)} className={'facebook-link'}
                                    target={'_blank'}>
                            <Facebook size={20}/>
                        </a>
                    })
                }
            }
        )
    },
];