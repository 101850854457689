import React, {Component} from 'react';
import {Col, Container, Row, Nav} from 'react-bootstrap';
import {Pencil, ListStars} from 'react-bootstrap-icons';
import {getAge} from 'utils/index';
import {TIME_FORMATS} from "constants/index";
import moment from 'moment';
import {AddButton, SubmitButton} from "components/Buttons";
import {Loader} from "components/Loader/Loader";
import {connect} from "react-redux";
import UserProfileForm from 'pages/profile_user/form';
import * as AppActions from "actions";
import {ModalComponent} from "components/Modal";
import PlayerHistoryList from "pages/profile_user/playerHistoryList";

class UserProfile extends Component {
    state = {
        openForm: false,
        openHistory: false
    };

    onOpenForm = () => {
        this.setState({
            openForm: true
        });
    };

    openHistory = () => {
        this.setState({
            openHistory: true
        });
        this.props.getPlayerHistory();
    };

    onCloseForm = () => {
        this.setState({
            openForm: false,
            openHistory: false
        });
    };

    onSaveProfile = async (values) => {
        await  this.props.updateUserProfile({
            ...values,
            cityId: +values.cityId,
            sizeId: +values.sizeId,
            height: +values.height,
            weight: +values.weight
        });
        this.props.getUserProfile();
        this.onCloseForm();
    };

    render() {
        const {citiesOptions, userProfile = {}, sizesOptions, saveLoading, playerHistory, loading} = this.props;
        const {openForm, openHistory} = this.state;
        
        return (
            <Container className={'page-container'}>
                {loading
                 ? <Row><Loader/></Row>
                 : <Row>
                     <Col xs={12} md={{span: 8, offset: 2}} lg={{span: 7, offset: 2.5}}
                          className={'profile-container container-with-flag'}>
                         <div className={'user-profile-image'}>
                             {userProfile && userProfile.photo
                              ? <img src={`data:image/jpeg;base64,${userProfile.photo}`} height={'300'}/>
                              : userProfile.gender === 2
                                ? <img src={process.env.PUBLIC_URL + 'woman_volleyball_player.png'}
                                       alt={'male profile'}
                                       height={'300'}/>
                                : <img src={process.env.PUBLIC_URL + 'man_volleyball_player.png'}
                                       alt={'male profile'}
                                       height={'300'}/>
                             }

                         </div>
                         <div className={'padding-large'}>
                           <div className={'user-profile-first-name'}>
                              {userProfile.firstName}&nbsp;{userProfile.lastName}
                           </div>
                           <div>{userProfile.cityName}</div>
                         </div>

                          <div className={'user-profile-details-root'}>
                            <div className={'user-profile-points-container'}>Total Points: <b>{userProfile.totalPoints}</b></div>
                          </div>

                         <div className={'padding-large'}>
                             <div className={'font-bold text-large'}>Tournaments History:</div>
                             <PlayerHistoryList playerHistory={playerHistory}/>
                         </div>
                     </Col>

                 </Row>
                }
                <ModalComponent
                    onHide={this.onCloseForm}
                    show={openForm}
                    title={'Update profile'}
                >
                    <UserProfileForm
                        citiesOptions={citiesOptions}
                        sizesOptions={sizesOptions}
                        saveLoading={saveLoading}
                        initialValues={{
                            ...userProfile,
                            birthdate: moment(userProfile.birthdate).format('YYYY-MM-DD')
                        }}
                        onSubmit={this.onSaveProfile}
                    />
                </ModalComponent>
                {/*<ModalComponent*/}
                    {/*onHide={this.onCloseForm}*/}
                    {/*show={openHistory}*/}
                    {/*title={`${userProfile ? userProfile.fullName : 'Player'} Tournaments History`}*/}
                {/*>*/}
                    {/*<PlayerHistoryList playerHistory={playerHistory}/>*/}
                {/*</ModalComponent>*/}

            </Container>
        );
    }

    componentDidMount() {
        this.props.getCities();
        this.props.getSizes();
        this.props.getUserProfile();
        this.props.getPlayerHistory();
    }

    componentWillUnmount() {
        this.props.resetCities();
        this.props.resetSizes();
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.appReducer.userProfile || {},
    loading: state.appReducer.loading,
    citiesOptions: state.appReducer.citiesList,
    sizesOptions: state.appReducer.sizesList,
    saveLoading: state.appReducer.saveLoading,
    playerHistory: state.appReducer.playerHistory
});

const mapDispatchToProps = (dispatch) => ({
    getCities: () => dispatch(AppActions.getCities()),
    resetCities: () => dispatch(AppActions.resetCities()),
    getSizes: () => dispatch(AppActions.getSizes()),
    resetSizes: () => dispatch(AppActions.resetSizes()),
    updateUserProfile: (values) => dispatch(AppActions.updateUserProfile(values)),
    getUserProfile: () => dispatch(AppActions.getUserProfile()),
    getPlayerHistory: () => dispatch(AppActions.getPlayerHistory())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(UserProfile);
export default withConnect;
