import React, {Component, Fragment} from 'react'
import {AddButton} from "components/Buttons";
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS} from 'constants/index';
import {connect} from 'react-redux';
import * as AppActions from 'actions';
import {PageInConstruction} from 'components/PageInConstruction';

class Admin_Sponsors extends Component{
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
    };
    
    onOpenForm = (item) => {
        this.setState({
            openForm: true,
            editItem: item
        })
    };
    
    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        })
    };
    render(){
        const {saveLoading, loading, sponsorsList, tournamentsList} = this.props;
        const {openForm, editItem, openConfirm, deleteItem, filter} = this.state;
        return(
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_SPONSORS, name: 'Sponsors'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Sponsors</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                    <PageInConstruction/>
                    {tournamentsList && tournamentsList.length > 0 &&
                     <Fragment>
                         {/*<TableComponent*/}
                             {/*data={sponsorsList}*/}
                             {/*header={header}*/}
                             {/*editable={true}*/}
                             {/*removable={true}*/}
                             {/*onEditItem={this.onOpenForm}*/}
                             {/*onDeleteItem={this.onDeleteItem}*/}
                             {/*loading={loading}*/}
                         {/*/>*/}
                     </Fragment>
                    }
                </div>
            </div>
        )
    }
    componentDidMount(){
        this.props.getSponsors();
    }
}

const mapStateToProps = (state) => ({
    sponsorsList: state.appReducer.sponsorsList,
    loading: state.appReducer.loading,
    tournamentsList: state.appReducer.managerTournamentsList,
    saveLoading: state.appReducer.saveLoading
});

const mapDispatchToProps = (dispatch) => ({
    getSponsors: (item) => dispatch(AppActions.getSponsors(item)),
    resetSponsors: () => dispatch(AppActions.resetSponsors()),
    
    // addTeam: (item) => dispatch(AppActions.addTeam(item)),
    // editTeam: (item) => dispatch(AppActions.editTeam(item)),
    // deleteTeam: (item) => dispatch(AppActions.deleteTeam(item)),
    
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Sponsors);
export default withConnect;