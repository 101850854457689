import React, {Component, Fragment} from 'react';
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS, TIME_FORMATS} from "constants/index";
import {Loader} from "components/Loader";
import {AddButton} from "components/Buttons";
import {TableComponent} from "components/Table";
import {ModalComponent, ConfirmModal} from "components/Modal";
import {connect} from "react-redux";
import * as AppActions from "actions";
import SchedulesForm from 'pages/admin_pages/schedule/form';
import ScheduleFilterForm from 'pages/admin_pages/schedule/filterForm';
import moment from 'moment';

class Admin_Schedule extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
        filter: {}
    };
    
    onOpenForm = (item) => {
        this.setState({
            openForm: true,
            editItem: item
        });
    };
    
    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        });
    };
    
    filterData = (values) => {
        
        this.props.getSchedules(values);
        this.setState({
            filter: values
        });
    };
    
    saveSchedule = async (values) => {
        if (this.state.editItem) {
            await this.props.editSchedule(values);
        }
        else {
            await this.props.addSchedule(values);
        }
        this.props.getSchedules(this.state.filter);
        this.closeForm();
    };
    
    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        });
    };
    
    onConfirmDelete = async () => {
        await this.props.deleteSchedule(this.state.deleteItem);
        this.props.getSchedules(this.state.filter);
        this.closeConfirm();
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        });
    };
    
    render() {
        const {schedulesList, saveLoading, loading, tournamentsList, managerTournamentsLoading} = this.props;
        const {openForm, editItem, openConfirm, deleteItem, filter} = this.state;
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_SCHEDULES, name: 'Schedules'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Schedules</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                    {managerTournamentsLoading
                     ? <div className={'align-center'}>
                         <Loader/>
                     </div>
                     : tournamentsList && tournamentsList.length > 0 &&
                       <Fragment>
                           <div className={'filter-container'}>
                               <ScheduleFilterForm
                                   tournamentsList={tournamentsList}
                                   onSubmit={this.filterData}
                                   initialValues={{
                                       ...filter,
                                       tournamentId: filter.tournamentId || tournamentsList[0].id
                                   }}
                               />
                           </div>
                        
                           <TableComponent
                               data={schedulesList}
                               header={header}
                               indexColumn
                               editable={true}
                               removable={true}
                               onEditItem={this.onOpenForm}
                               onDeleteItem={this.onDeleteItem}
                               loading={loading}
                           />
                        
                           <ModalComponent
                               show={openForm}
                               onHide={this.closeForm}
                               title={editItem ? 'Update Schedule' : 'Add New Schedule'}
                           >
                               <SchedulesForm
                                   initialValues={editItem
                                                  ? {
                                           ...editItem,
                                           date: moment(editItem.date).format('YYYY-MM-DD')
                                       }
                                                  : {
                                           ...filter,
                                           tournamentID: filter.tournamentId || tournamentsList[0].id
                                       }}
                                   onSubmit={this.saveSchedule}
                                   tournamentsList={tournamentsList}
                                   saveLoading={saveLoading}
                               />
                           </ModalComponent>
                        
                           <ConfirmModal
                               show={openConfirm}
                               onHide={this.closeConfirm}
                               title={`Remove Schedule`}
                               handleConfirm={this.onConfirmDelete}
                               handleClose={this.closeConfirm}
                               content={`Are you sure you want to remove ${deleteItem.name} schedule?`}
                           />
                       </Fragment>
                    }
                </div>
            </div>
        );
    }
    
    componentWillUnmount() {
        this.props.resetSchedules();
        this.props.resetTournaments();
    }
    
    async componentDidMount() {
        const tournaments = await this.props.getTournaments();
        if (tournaments && tournaments.length > 0) {
            await this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    tournamentId: tournaments[0].id
                }
            }));
            this.props.getSchedules({tournamentId: tournaments[0].id});
        }
    }
    
}

const mapStateToProps = (state) => ({
    schedulesList: state.appReducer.schedulesList,
    loading: state.appReducer.loading,
    managerTournamentsLoading: state.appReducer.managerTournamentsLoading,
    tournamentsList: state.appReducer.managerTournamentsList,
    saveLoading: state.appReducer.saveLoading
});

const mapDispatchToProps = (dispatch) => ({
    getSchedules: (params) => dispatch(AppActions.getSchedules(params)),
    resetGames: () => dispatch(AppActions.resetGames()),
    addSchedule: (item) => dispatch(AppActions.addSchedule(item)),
    editSchedule: (item) => dispatch(AppActions.editSchedule(item)),
    deleteSchedule: (item) => dispatch(AppActions.deleteSchedule(item)),
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Schedule);
export default withConnect;

const header = [
    {display: 'Schedule Name', property: 'name'},
    {
        display: 'Date', property: 'date',
        map: ((item, index) => ({
                display: <span key={index}>
                    {moment(item.date).format(TIME_FORMATS.DAY_MONTH_YEAR)}
                </span>
            })
        )
    },
    {display: 'Tournament', property: 'tournamentName'}
];