import React from 'react';
import {PATH_URLS, EVENT_CATEGORY} from 'constants/index';
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {AddButton} from "components/Buttons";
import {TableComponent} from "components/Table";
import {ModalComponent, ConfirmModal} from "components/Modal";
import * as AppActions from 'actions';
import {connect} from 'react-redux';
import {Check} from 'react-bootstrap-icons';

const header = [
    {
        display: 'Username',
        property: 'username',
    },
    {
        display: 'Is Admin',
        property: 'isAdmin',
        map: ((item, index) => ({
                display: item.isAdmin && <Check/>
            })
        )
    },
    {
        display: 'Is SuperAdmin',
        property: 'isSuperAdmin',
        map: ((item, index) => ({
                display: item.isSuperAdmin && <Check/>
            })
        )
    },
];

class Admin_Users extends React.Component {
    render() {
        const {usersList, loading} = this.props;
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_USERS, name: 'Users'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Users</div>
                        <AddButton
                            // onClick={() => this.onOpenForm(null)}
                        />
                    </div>
                    <TableComponent
                        data={usersList}
                        header={header}
                        // editable={true}
                        // removable={true}
                        // onEditItem={this.onOpenForm}
                        // onDeleteItem={this.onDeleteItem}
                        loading={loading}
                    />
                </div>
            </div>
        );
    }
    
    componentDidMount() {
        this.props.getUsers();
    }
    
    componentWillUnmount() {
        this.props.resetUsers();
    }
}

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading,
    saveLoading: state.appReducer.saveLoading,
    usersList: state.appReducer.usersList,
});

const mapDispatchToProps = (dispatch) => ({
    getUsers: (item) => dispatch(AppActions.getUsers(item)),
    resetUsers: () => dispatch(AppActions.resetUsers())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Users);
export default withConnect;