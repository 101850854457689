import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {CheckCircleFill} from 'react-bootstrap-icons';
import {Formik, Field, Form} from 'formik';
import {readInputFile} from 'utils/file';
import {CustomInputComponent} from 'components/RenderInput';
import {SubmitButton, SpinnerButton} from "components/Buttons";

const validate = (values, error, warning, props /* only available when using withFormik */) => {
    const errors = {};
    
    if (!values.name) {
        errors.name = 'Required';
    }
    if (warning) {
        errors.file = warning;
    }
    if (error){
        errors.file = error;
    }
    
    return errors;
};

const emptyFormObject = {
    file: '',
    name: '',
    tournamentId: '',
};

const AgreementFilesForm = ({onSubmit, initialValues, saveLoading}) => {
    const [uploadedFile, setUploadedFile] = useState({});
    const [warning, setWarning] = useState(null)
    const [error, setError] = useState('You must upload a file')
    
    const onChangeHandler = async (event, formProps) => {
        const CvFile = [await readInputFile(event.target.files[0])];
        const filesObjectList = await Promise.all(CvFile);
        if(filesObjectList && filesObjectList[0]){
            setError(null);
        }
        if (filesObjectList[0].fileType !== 'application/pdf') {
            setWarning('You must upload pdf files');
        }
        else {
            setWarning(null);
        }
        formProps.setFieldValue('name', filesObjectList[0].fileName)
    
        setUploadedFile(filesObjectList[0])
    };
    
    return (
        <Formik
            initialValues={initialValues || emptyFormObject}
            onSubmit={(values)=>onSubmit(values, uploadedFile)}
            validate={(values) => validate(values, error, warning)}
        >
            {formProps => <Form>
                <Row>
                    <Col xs={12} md={6} className={'padding-small'}>
                        <Field
                            name="file"
                            component={CustomInputComponent}
                            label={'File'}
                            type={'file'}
                            onChange={event => onChangeHandler(event, formProps)}
                            accept=".pdf"
                        />
                    </Col>
                    <Col xs={12} md={6} className={'padding-small'}>
                        <Field
                            name="name"
                            component={CustomInputComponent}
                            label={'File Name'}
                        />
                    </Col>
                    
                </Row>
                <Row>
            
                    <Col xs={12} className={'text-right padding-small'}>
                        {saveLoading
                         ? <SpinnerButton label={'Saving'} />
                         : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>} disabled={warning}/>
                        }
                    </Col>
                </Row>
            </Form>
            }
        </Formik>
    
    )
};

export default AgreementFilesForm;
