import React, {Fragment, Component} from 'react';
import {AddButton} from "components/Buttons";
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {TableComponent} from "components/Table";
import {ModalComponent, ConfirmModal} from "components/Modal";
import {PATH_URLS, EVENT_CATEGORY} from 'constants/index';
import {connect} from 'react-redux';
import * as AppActions from 'actions';
import {CircleFill, ListStars} from 'react-bootstrap-icons';
import PlayersForm from 'pages/admin_pages/players/form';
import PlayersFilterForm from 'pages/admin_pages/players/filterForm';

class Admin_Players extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
        filter:{
            category: 0
        }
    };
    getFilterParams = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }
        return {categoryId: category, cityId: values.cityId};
    };
    filterData = (values) => {
        let params = this.getFilterParams(values);
        this.props.getPlayers(params);
        this.setState({
            filter: values
        })
    };
    onOpenForm = (item) => {
        this.setState({
            openForm: true,
            editItem: item
        });
    };
    
    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        });
    };
    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        });
    };
    
    onConfirmDelete = async () => {
        await this.props.deletePlayer(this.state.deleteItem);
        this.closeConfirm();
        let params = this.getFilterParams(this.state.filter);
        this.props.getPlayers(params);
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        });
    };
    
    savePlayer = async (values) => {
        if (this.state.editItem) {
            await this.props.editPlayer(values);
        } else {
            await this.props.addPlayer(values);
        }
        this.closeForm();
        let params = this.getFilterParams(this.state.filter);
        this.props.getPlayers(params);
    };
    
    render() {
        const {loading, playersList, saveLoading, citiesOptions, switchRoute} = this.props;
        const {editItem, openForm, openConfirm, filter, deleteItem} = this.state;
        
        const otherButtons = [
            {
                action: (item) => switchRoute(`${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_PLAYERS_HISTORY}/${item.id}`),
                label: 'View History',
                icon: <ListStars/>
            }
        ];
        
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_PLAYERS, name: 'Players'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Players</div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                    
                    <div className={'filter-container'}>
                        <PlayersFilterForm
                            onSubmit={this.filterData}
                            citiesOptions={citiesOptions}
                            initialValues={filter}
                        />
                    </div>
                    
                    <TableComponent
                        data={playersList}
                        header={header}
                        editable={true}
                        removable={true}
                        onEditItem={this.onOpenForm}
                        onDeleteItem={this.onDeleteItem}
                        loading={loading}
                        otherButtons={otherButtons}
                    />
                    
                    <ModalComponent
                        show={openForm}
                        onHide={this.closeForm}
                        title={editItem ? 'Update Player' : 'Add New Player'}
                    >
                        <PlayersForm
                            initialValues={editItem && editItem}
                            onSubmit={this.savePlayer}
                            citiesOptions={citiesOptions}
                            saveLoading={saveLoading}
                        />
                    </ModalComponent>
                    
                    <ConfirmModal
                        show={openConfirm}
                        onHide={this.closeConfirm}
                        title={`Remove Player`}
                        handleConfirm={this.onConfirmDelete}
                        handleClose={this.closeConfirm}
                        content={`Are you sure you want to remove ${deleteItem.fullName} player?`}
                    />
                
                </div>
            </div>
        );
    }
    
    componentWillUnmount() {
        this.props.resetPlayers();
        this.props.resetCities();
    }
    
    componentDidMount() {
        this.props.getPlayers();
        this.props.getCities();
    }
}

const mapStateToProps = (state) => ({
    playersList: state.appReducer.playersList,
    loading: state.appReducer.loadingPlayers,
    tournamentsList: state.appReducer.managerTournamentsList,
    saveLoading: state.appReducer.saveLoading,
    citiesOptions: state.appReducer.citiesList
});

const mapDispatchToProps = (dispatch) => ({
    switchRoute: (path) => dispatch(AppActions.switchRoute(path)),
    getPlayers: (item) => dispatch(AppActions.getPlayers(item)),
    resetPlayers: () => dispatch(AppActions.resetPlayers()),
    getCities: () => dispatch(AppActions.getCities()),
    resetCities: () => dispatch(AppActions.resetCities()),
    addPlayer: (item) => dispatch(AppActions.addPlayer(item)),
    editPlayer: (item) => dispatch(AppActions.editPlayer(item)),
    deletePlayer: (item) => dispatch(AppActions.deletePlayer(item))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Players);
export default withConnect;

const header = [
    {
        display: 'Player Name',
        property: 'fullName',
        map: ((item, index) => ({
                display: <div key={index}>
                    <CircleFill color={item.gender === EVENT_CATEGORY.MALE ? '#002b7f' : '#fcd116'}/>&nbsp;
                    {item.fullName}
                </div>
            })
        )
    },
    {
        display: 'City',
        property: 'cityName'
    },
    {display: 'Total Points', property: 'totalPoints'}
];