import React from 'react';
import Layout from 'pages/layout';
import {connect} from "react-redux";
import * as AppActions from "actions";
import {history} from 'config/redux';
import { ConnectedRouter } from 'connected-react-router'
import Notification from 'components/Notification/Notification';

class App extends React.Component {
    render() {
        const {loading, isLoggedIn, logout} = this.props;
        return (
            <ConnectedRouter history={history}>
                <Layout
                    loading={loading}
                    isLoggedIn={isLoggedIn}
                    logout={logout}
                />
                <Notification/>
            </ConnectedRouter>
        );
    }

    componentDidMount() {
        this.props.refreshLogin();
    }

    componentWillUnmount() {
    }
}

const mapStateToProps = (state) => ({
    loading: state.appReducer.loading,
    isLoggedIn: state.appReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    login: (credentials) => dispatch(AppActions.login(credentials)),
    logout: () => dispatch(AppActions.logout()),
    refreshLogin: () => dispatch(AppActions.refreshLogin()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(App);
export default withConnect;
