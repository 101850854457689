import React, {Component} from 'react';
import {AddButton} from "components/Buttons";
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS} from 'constants/index';
import {PageInConstruction} from 'components/PageInConstruction';

class Admin_TshirtSizes extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        editItem: {},
        deleteItem: {},
    };
    
    onOpenForm = (item) => {
        this.setState({
            openForm: true,
            editItem: item
        })
    };
    
    closeForm = () => {
        this.setState({
            openForm: false,
            editItem: {}
        })
    };
    render(){
        return(
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_TSHIRT_SIZES, name: 'T-shirt Sizes'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        T-shirt Sizes
                    </div>
                    <div>
                        <AddButton onClick={() => this.onOpenForm(null)}/>
                    </div>
                    <PageInConstruction/>
                </div>
            </div>
        )
    }
}

export default Admin_TshirtSizes;